<template>
  <ModalsTemplate
      key="registerForm"
      :modal-id='"registerForm"'
  >
  <template v-slot:slot-modal-title>
    <SmallModalLogo class='modal-logo'/>
  </template>

  <template v-slot:slot-close-modal>
    <div class='close cp' @click='closeModal'>
      <CloseIcon/>
    </div>
  </template>
  <template v-slot:slot-modal-form>
    <div class='title modal-title'>Sign Up</div>
    <div class='form register-form'>
      <div class="form_m">
        <div class="flex flex-column gap-20p">
          <div class="flex justify-content-between gap-20p names">
            <div class=" d_w_50">
              <label class="form-label">First Name</label>
              <input v-model='registerData.first_name' type="text" class="form-control" placeholder="">
              <div class="form-error" v-if='registerDataValidator.first_name'>
                {{ registerDataValidator.first_name }}
              </div>
            </div>
            <div class=" d_w_50">
              <label class="form-label">Last Name</label>
              <input v-model='registerData.last_name' type="text" class="form-control" placeholder="">
              <div class="form-error" v-if='registerDataValidator.last_name'>
                {{ registerDataValidator.last_name }}
              </div>
            </div>
          </div>
          <div class="">
            <label class="form-label">Email</label>
            <input v-model='registerData.email' type="text" class="form-control" placeholder="">
            <div class="form-error" v-if='registerDataValidator.email'>
              {{ registerDataValidator.email }}
            </div>
          </div>
          <div class="">
            <label class="form-label">Company name</label>
            <input v-model='registerData.company_name' type="text" class="form-control" placeholder="">
            <div class="form-error" v-if='registerDataValidator.company_name'>
              {{ registerDataValidator.company_name }}
            </div>
          </div>
          <div class="flex justify-content-between gap-20p names">
            <div class=" d_w_50">
              <label class="form-label">Phone number</label>
              <div class='position-relative fake-tel-input'>
                <input v-model='registerData.phone'
                       id='fakeTelInput'
                       type="text" class="form-control" placeholder="">
                <vue-tel-input
                  @input='inputPhone'
                  :value='buffer_string'
                  v-bind="telOptions">
                  <template v-slot:arrow-icon>
                    <ArrowDownBlue/>
                  </template>
                </vue-tel-input>
              </div>
              <div class="form-error" v-if='registerDataValidator.phone'>
                {{ registerDataValidator.phone }}
              </div>
            </div>
            <div class="d_w_50 position-relative">
              <label class="form-label">Your company’s focus</label>
              <VueSelect
                  label='item'
                  class='item'
                  :options='company_focus'
                  v-model='company_focus_watch'
                  placeholder='Please select...'
              >
                <template v-slot:option='option'>
                  {{ option.item }}
                </template>
                <template v-slot:selected-option='option'>
                  {{ option.item }}
                </template>
              </VueSelect>
              <div class="form-error" v-if='registerDataValidator.company_focus'>
                {{ registerDataValidator.company_focus }}
              </div>
            </div>
          </div>
          <div class="flex justify-content-between gap-20p names">
            <div class="d_w_50">
              <label class="form-label">Password</label>
              <div class='position-relative'>
                <input
                    :type='passwordType'
                    ref='password' v-model="registerData.password" class="form-control">
                <div class='eye cp position-absolute'>
                  <EyeOn @click='passwordShow = !passwordShow'/>
                </div>
                <div class="form-error" v-if='registerDataValidator.password'>
                  {{ registerDataValidator.password }}
                </div>
              </div>
            </div>
            <div class=" d_w_50">
              <label class="form-label">Re-type password</label>
              <div class='position-relative'>
                <input
                    :type='passwordConfirmType'
                    ref='passwordConfirm' v-model='registerData.password_confirm' class="form-control" placeholder="">
                <div class='eye cp position-absolute'>
                  <EyeOn @click='passwordConfirmShow = !passwordConfirmShow'/>
                </div>
                <div class="form-error" v-if='registerDataValidator.password_confirm'>
                  {{ registerDataValidator.password_confirm }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='terms flex gap-16p align-items-start justify-content-start'>
          <input id='termsInput' class='mt-3p' type='checkbox' v-model='registerData.terms'>
          <span class='flex flex-column gap-4p ' >
            <span>
                I agree to the
                <a class='link bold' href=''>Term of Use</a>
                and
                <a class='link bold' href=''>Privacy Policy</a>
              </span>
          <div class="form-error" v-if='registerDataValidator.terms'>
            {{ registerDataValidator.terms }}
          </div>
          </span>
        </div>
      </div>
      <div class='actions flex justify-content-between align-items-center'>
        <div class='to-login flex flex-column w_50 text-center'>
          <div class=''>Already have an account?</div>
          <button class="link bold-link btn no-btn" @click='openSignIn'>Sign In</button>
        </div>
        <button class="btn bold w_50 light-blue" @click='registerNewUser'>Sign Up</button>
      </div>
    </div>
  </template>
  </ModalsTemplate>
</template>

<script>
import getFocus from "@/store/company_focus.json";
import {VueTelInput} from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import VueSelect from "vue-select";
import EyeOn from "@/components/SVG/EyeOn";
import ModalsTemplate from "@/components/Layouts/ModalsTemplate";
import {joinNDA, receiveNDA, registerUser} from "@/services/api";
import {mapMutations} from "vuex";
import {isNotValidEmail, saveObjectToStorage} from "@/services/helpers";
import {ErrorMesages, FormFieldValidators, modalIds} from "@/services/enums";
import ArrowDownBlue from "@/components/SVG/ArrowDownBlue";
import CloseIcon from "@/components/SVG/CloseIcon";
import SmallModalLogo from "@/components/SVG/SmallModalLogo";
import {timeToISO} from "@/services/time.helper";

export default {
  name: "SignUpModal",
  data() {
    return {
      company_focus: getFocus,
      company_focus_watch: null,
      passwordShow: false,
      passwordConfirmShow: false,
      buffer_string: null,
      registerData: {
        first_name: null,
        last_name: null,
        email: null,
        company_name: null,
        phone: null,
        country_code: null,
        company_focus: null,
        password: null,
        password_confirm: null,
        terms: false,
      },
      registerDataValidator: {
        first_name: null,
        last_name: null,
        email: null,
        company_name: null,
        phone: null,
        company_focus: null,
        password: null,
        password_confirm: null,
        terms: null,
      },
      passwordType: 'password',
      passwordConfirmType: 'password',
    }
  },
  methods: {
    ...mapMutations([
      'setCloseModal',
      'setOpenModal',
      'setModalError',
      'showLoader',
      'hideLoader',
      'setLoggedUser'
    ]),
    inputPhone(phone, phoneObject) {
      if(phoneObject?.formatted) {
        this.registerData.country_code = `+${phoneObject?.countryCallingCode}`;
      }
    },
    async registerNewUser() {
      await this.clearValid();
      const isValidData = await this.isValidFormRegistration();
      if (!isValidData) {
        return;
      }
      this.showLoader();
      try {
        const {user, access_token, error} = await registerUser(this.registerData);
        if (error) {
          this.hideLoader();
          return await this.setModalError(error)
        }
        await this.setCloseModal(modalIds.registerForm)
        await localStorage.setItem('user_jwt', access_token);
        await saveObjectToStorage('stored_user', user)
        await this.setLoggedUser(user)
        await this.checkRecievedNda();
        await this.$router.push({name: 'UserDashboard'})
      } catch (e) {
        console.log(e)
      }
      this.hideLoader();
    },
    async checkRecievedNda() {
      const isNda = await JSON.parse(localStorage.getItem('new_user_nda'));
      if (isNda && isNda.id) {
        await receiveNDA(isNda.id)
        await localStorage.removeItem('new_user_nda')
      }

      const joinderNda = await JSON.parse(localStorage.getItem('new_nda_joinder'));
      if (joinderNda && joinderNda?.nda?.nda_id) {
        await joinNDA({nda_id: joinderNda?.nda?.nda_id, user_id: joinderNda?.nda?.user_id, created_date: timeToISO(Date.now())});
        await localStorage.removeItem('new_nda_joinder')
      }

    },
    async closeModal() {
      await this.setCloseModal(modalIds.registerForm)
    },
    async openSignIn() {
      await this.setCloseModal(modalIds.registerForm)
      await this.setOpenModal(modalIds.loginForm)
    },
    async isValidFormRegistration() {
      let is_valid = true;
      if (!this.registerData.first_name) {
        this.registerDataValidator.first_name = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (!this.registerData.last_name) {
        this.registerDataValidator.last_name = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (!this.registerData.company_name) {
        this.registerDataValidator.company_name = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (!this.registerData.phone || this.registerData.phone.length < 5) {
        this.registerDataValidator.phone = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (!this.registerData.company_focus) {
        this.registerDataValidator.company_focus = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (!this.registerData.password) {
        this.registerDataValidator.password = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (this.registerData.password && this.registerData.password.length < FormFieldValidators.passwordLength) {
        this.registerDataValidator.password = ErrorMesages.minLength8Field;
        is_valid = false;
      }
      if (!this.registerData.password_confirm) {
        this.registerDataValidator.password_confirm = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (this.registerData.password_confirm !== this.registerData.password) {
        this.registerDataValidator.password_confirm = ErrorMesages.checkField;
        is_valid = false;
      }
      if (!this.registerData.terms) {
        this.registerDataValidator.terms = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (!this.registerData.email) {
        this.registerDataValidator.email = ErrorMesages.reqiuredField;
        is_valid = false;
      }
      if (this.registerData.email) {
        if (isNotValidEmail(this.registerData.email)) {
          this.registerDataValidator.email = ErrorMesages.incorrectFormatField;
          is_valid = false;
        }
      }
      return is_valid;
    },
    clearValid() {
      this.setModalError(null)
      const validator = this.registerDataValidator;
      Object.keys(validator).forEach(field => {
        validator[field] = null;
      })
    },
  },

  watch: {
    "registerData.phone": {
      async handler(val){
        if (val.length > 10) {
          return this.registerData.phone = val.slice(0, -1);
        }
        this.buffer_string = val;
      },

      deep: true
    },
    company_focus_watch(val) {
      this.registerData.company_focus = val.item;
    },
    passwordShow(val){
      if (!val) {
        return this.passwordType = 'password'
      }
      return this.passwordType = 'text'
    },
    passwordConfirmShow(val){
      if (!val) {
        return this.passwordConfirmType = 'password'
      }
      return this.passwordConfirmType = 'text'
    },

  },
  computed: {
    telOptions() {
      return {
        dynamicPlaceholder: true,
        placeholder: '000 000 0000',
        preferredCountries: ["US"],
        mode: "international",
        inputOptions: {
          // showDialCode: true,
          tabindex: 0,
          maxlength: 10
        },
        dropdownOptions: {
          showFlags: true,
          showDialCodeInSelection: true
        },
        wrapperClasses: "country-phone-input"
      }
    }
  },
  components: {
    ModalsTemplate,
    VueTelInput,
    VueSelect,
    EyeOn,
    ArrowDownBlue,
    CloseIcon,
    SmallModalLogo
  }
}
</script>

<style lang='scss' scoped>
#registerForm {
  #termsInput {
  }
  .register-form.form {
    .terms {
      padding-top: 24px;
      padding-bottom: 24px;
      .link {
        text-decoration: none;
        font-weight: 700;
      }
    }

    .eye {
      top: 9px;
      right: 10px;
    }
  }
  @media screen and (max-width: 992px) {
    #termsInput {
      margin-top: -2px;
    }
    .names {
      flex-direction: column;
    }
    .title_modal {
      font-size: 13px;
      line-height: 19px;
    }
    .terms {
      font-size: 11px;
      line-height: 16px;
    }
    .to-login {
      font-size: 11px;
      line-height: 16px;
      .btn {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
}
</style>
