<template>
  <div class='footer flex align-items-center justify_content_center'>
    <div class='inner flex align-items-center gap-40p'>
      <div class='simple_logo'>
        <SmallLogo class='md-visible'/>
        <SimpleLogo class='sm-visible'/>
      </div>
     <span class='flex justify-content-between align-items-center gap-8p md-visible'>
       <EmailIconSmall/>
        <a class='bold-link'
           href="mailto:info@handshakenda.com">info@handshakenda.com</a>
     </span>
      <div class="copyrights">

     <span class='flex justify-content-end align-items-center gap-8p sm-visible'>
       <EmailIconSmall/>
        <a class='bold-link'
           href="mailto:info@handshakenda.com">info@handshakenda.com</a>
     </span>
     <span class="info">
       © {{new Date().getFullYear()}}
      <span class='bold'>
      Handshake NDA
      </span> ® - All Rights Reserved
     </span>
      </div>    </div>
  </div>
</template>

<script>

import SmallLogo from "@/components/SVG/SmallLogo";
import SimpleLogo from "@/components/SimpleLogo";
import EmailIconSmall from "@/components/SVG/EmailIconSmall";

export default {
  name: "PageFooter",
  components: {
    SmallLogo,
    SimpleLogo,
    EmailIconSmall
  }
}
</script>

<style lang='scss' scoped>
.footer {
  height: 60px;
  background: #FAFAFD;;
  .simple_logo {
  }
  .copyrights {
    width: 100%;
    height: 60px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #7191BF;
    .info {
      padding-top: 20px;
      display: block;
    }
  }
  .bold-link {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
    justify-content: unset;
    .bold-link {
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
    }
    .inner {
      width: 100%;
      & > div {
        width: 50%;
        &.copyrights {
          width: 100%;
        }
      }

    }
    .copyrights {
      padding-top: 10px;
      .info {
        text-align: right;
        font-size: 9px;
        line-height: 14px;
        padding-top: 8px;
      }
    }
    .simple_logo {
      display: block;
    }
  }
}
</style>
