<template>
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.454866 1.67047L7 5.76118L13.5451 1.67047C13.1783 1.2247 12.6224 0.94043 12 0.94043H2C1.37764 0.94043 0.821678 1.2247 0.454866 1.67047ZM14 3.74468L7 8.11968L0 3.74468V10.9404C0 12.045 0.89543 12.9404 2 12.9404H12C13.1046 12.9404 14 12.045 14 10.9404V3.74468Z" fill="#03A9F4"/>
  </svg>
</template>

<script>
export default {
  name: "EmailIcon"
}
</script>

<style scoped>

</style>
