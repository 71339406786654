<template>
  <div class=" w_100 flex align-items-center loader"
       v-show="isLoading"
       style="background-color: rgba(248, 250, 252, 0.85);">
      <span class="font-medium text-grey-darker text-lg pb-4 text-center">
      <div class="spinner"></div>
    </span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PageLoader",
  computed: {
    ...mapGetters([
        'isLoading'
    ])
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  z-index: 99999999999;
  height: calc(100vh + 50%);
  transition: 0.5s;
  .spinner {
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 35vh;
    margin: auto;
    width: 120px;
    padding: 15px;
    background: rgba(3, 169, 244, 1);

    aspect-ratio: 1;
    border-radius: 50%;
    --_m:
        conic-gradient(#0000,#000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    box-sizing: border-box;
    animation: load 1.8s linear infinite;
  }
  @keyframes load {
    to{transform: rotate(1turn)}
  }
}
</style>
