<template>
  <div class='layout-wrap flex-column flex justify-content-between'>
    <PageHeader/>
    <div class='layout page flex '
         :class='{"align-items-center":
         !["UserDashboard", "UserArchiveDashboard"].includes($route.name),
          "mobile-height": ["UserDashboard", "UserArchiveDashboard"].includes($route.name)}'
    >
      <slot></slot>
    </div>
    <PageFooter/>
    <PageLoader/>
  </div>
</template>

<script>

import PageHeader from "@/components/PageHeader";
import PageLoader from "@/components/PageLoader";
import PageFooter from "@/components/PageFooter";

export default {
  name: "MainLayout",
  components: {
    PageHeader,
    PageFooter,
    PageLoader
  }
}
</script>

<style lang='scss' scoped>
.layout-wrap,
.layout {
  min-height: calc(100vh - 140px);
  &.page {
    background: #F5F6FB;
    margin-top: 82px;
  }
}
@media screen and (max-width: 992px) {
  .layout {
    min-height: calc(100vh - 100px);
    height: 100%;
    &.page {
      background: #F5F6FB;
      margin-top: 62px;
    }
    &.mobile-height {
      min-height: calc(100vh - 125px);
    }
  }
}
</style>
