export class FormFieldValidators {
    static passwordLength = 8;
}
export class modalIds {
    static registerForm = 'registerForm';
    static loginForm = 'loginForm';
    static NDASuccess = 'NDASuccess';
    static SuccessRestoredNDAModal = 'SuccessRestoredNDAModal';
    static NDASuccessNewVersion = 'NDASuccessNewVersion';
    static logOutPopUp = 'logOutPopUp';
    static ProposeTerms = 'ProposeTerms';
    static ProposeExceptionTerms = 'ProposeExceptionTerms';
    static ProposeTermsException = 'ProposeTermsException';
    static ChangePassword = 'ChangePassword';
}

export class NDASteps {
    static firstStep = 1;
    static secondStep = 2;
    static thirdStep = 3;
    static fourthStep = 4;
}


export class PaginatorValues {
    static defaultRowsCount = 10;
    static threeRowsCount = 3;
}

export class ErrorMesages {
    static reqiuredField = 'This field is required.';
    static reqiuredFields = 'Please fill all required fields.';
    static fillReqiuredFields = 'Please fill in required field.';
    static oneReqiuredField = 'One of this fields is required.';
    static minLength8Field = 'Minimum password length is 8 characters.';
    static checkField = 'Please check this field.';
    static incorrectFormatField = 'Incorrect email format. Please try again';
}

export class NDAStatuses {
    static forwarded = 'forwarded';
}

export class NDAStatusColumn {
    static waiting_for_you = 'waiting_for_you';
    static new_nda = 'new';
    static signed = 'signed';
    static pending_review = 'pending_review';
    static pending_changes = 'pending_changes';
    static pending_review_tmp = 'pending_review_tmp';
    static joined = 'joined';
}

export class UserNDATypes {
    static was_forwarded = 'was_forwarded'
}
