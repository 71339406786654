import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import VueSignaturePad from 'vue-signature-pad';
import vClickOutside from "click-outside-vue3"

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vClickOutside);
app.use(VueSignaturePad);
app.mount('#app');
