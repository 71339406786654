<template>
  <div class='land-block'>
    <div class='inner'>
      <div class='main-title text-center'>All the NDA functionality you want, and nothing you don't</div>
      <div id='featuresSlider' class='flex w_100 align-items-center'>
        <div class='w_50 md-visible'>
          <div class='image'>
            <img :src='image_src' alt='slider image'
                 :style='{opacity: loader_width < 5 || loader_width > 95 ? 0 : 1}'>
          </div>
        </div>
        <div class='w_50 flex flex-column gap-10p'>

          <div class='item cp'
               @click='setSlide(index)'
               :class='{"active": index === currentIndex && slider_initialized}'
               v-for='(feature, index) in slider_features' :key='feature.title'>
            <div class='image m-visible'>
              <img :src='image_src' alt='slider image'
                   :style='{opacity: loader_width < 5 || loader_width > 95 ? 0 : 1}'>
            </div>
            <div class='wrap'>
              <div class='title'>{{feature.title}}</div>
              <div class='info'>{{feature.info}}</div>
              <div class='progress w_100'>
                <div class='loaded' :style='{width: index === currentIndex ? loader_width + "%" : 0 + "%"}'>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sliderFeatures from "@/store/landing_slider_features.json"
export default {
  name: "FeaturesSlider",
  data() {
    return {
      slider_features: sliderFeatures,
      image_src: null,
      currentIndex: 0,
      loader_width: 0,
      timer: null,
      loader: null,
      slider_timeout_ms: 8000,
      slider_initialized: false
    }
  },
  mounted() {
    this.scroll();
  },

  methods: {
    resolve_img_url (path) {
      return require(`@/assets/${path}`)
    },
    scroll () {
      let c = window.document.querySelector('#featuresSlider').getBoundingClientRect();
      let scrolltop = c.top;
      window.onscroll = () => {
        if(document.documentElement.scrollTop > (scrolltop - 200) && !this.slider_initialized) {
          this.startSlide();
          this.slider_initialized = true
        }
      }
    },
    startSlide(is_set_slide = false) {
      if (!is_set_slide) {
        this.image_src = this.resolve_img_url(this.slider_features[0].img_path);
      }
      this.timer = setInterval(this.next, this.slider_timeout_ms);
      this.loader = setInterval(() => {
        this.loader_width += 0.25;
      }, this.slider_timeout_ms/400)
    },
    next() {
      this.loader_width = 0;
      if (this.currentIndex < sliderFeatures.length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }
      this.image_src = this.resolve_img_url(this.currentImg);
    },
    setSlide(index) {
      this.currentIndex = index;
      clearInterval(this.timer);
      clearInterval(this.loader);
      this.startSlide(true)
      this.loader_width = 0;
      this.image_src = this.resolve_img_url(this.slider_features[index].img_path);
    }
  },
  computed: {
    currentImg() {
      return this.slider_features[this.currentIndex].img_path;
    }
  }
}
</script>

<style lang='scss' scoped>
.land-block {
  .m-visible {
    display: none;
  }
  .image > img {
    transition: all .4s;
    opacity: 0;
  }
  .main-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 30px;
  }
  .item {
    .wrap {
      transition: all .4s;
      padding: 15px;
      gap: 10px;
      display: flex;
      flex-direction: column;

      .progress {
        display: none;
      }

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #03A9F4;
      }

      .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .image {
    max-width: 100%;
    img {
      max-width: 100%;
    }
  }

  .item.active {
    .wrap {
      transition: all .5s;
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(1, 84, 122, 0.1);
      border-radius: 15px;
      .title {
        color: #013349;
      }

      .progress {
        background: #DEEDFF;
        border-radius: 5px;
        height: 6px;
        display: block;

        .loaded {
          border-radius: 5px;
          height: 6px;
          background: #1DBA1D;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .m-visible {
      display: block;
      margin-bottom: 40px;
    }
    .main-title {
      padding-bottom: 25px;
    }
    .inner {
      .item {
        .wrap {
          .title {
            font-weight: 700;
            font-size: 19px;
            line-height: 24px;
          }
          .info {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
          }
        }
        .image.m-visible {
          display: none;
        }
      }
      .item.active {
        .image.m-visible {
          display: block;
        }
      }
    }
    .w_50 {
      width: 100%;
    }
  }
}
</style>
