<template>
  <svg width="181" height="40" viewBox="0 0 181 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.1811 25.2533V20.9295H43.9239V25.2533H41.9087V14.56H43.9239V19.0667H49.1811V14.56H51.1811V25.2533H49.1811Z" fill="black"/>
    <path d="M61.1888 23.2228H55.5965L54.6784 25.2381H52.4917L57.2879 14.5447H59.486L64.2822 25.2381H62.0841L61.1812 23.2228H61.1888ZM58.3927 16.7924L56.4079 21.3447H60.3812L58.3965 16.7924H58.3927Z" fill="black"/>
    <path d="M72.9182 21.7409V14.5447H74.9334V25.2381H73.2991L67.6153 18.1181V25.2381H65.6001V14.5447H67.2344L72.9182 21.7409Z" fill="black"/>
    <path d="M87.0212 19.8019C87.0669 22.5067 85.4174 25.2419 81.6726 25.2419H77.4707V14.5486H81.6726C85.3374 14.5486 86.9755 17.1619 87.0212 19.8057V19.8019ZM79.4745 23.2991H81.6764C84.1069 23.2991 85.0669 21.5276 85.0212 19.7867C84.9755 18.1219 83.9964 16.4572 81.6764 16.4572H79.4745V23.3029V23.2991Z" fill="black"/>
    <path d="M95.3146 17.1276C94.9488 16.5181 93.9393 15.9352 92.7774 15.9352C91.2803 15.9352 90.5641 16.56 90.5641 17.3562C90.5641 18.2895 91.665 18.5486 92.9488 18.7009C95.1812 18.9752 97.2574 19.5581 97.2574 22.1067C97.2574 24.4914 95.1507 25.5124 92.766 25.5124C90.5831 25.5124 88.8993 24.8419 88.1069 22.8838L89.7869 22.0114C90.2593 23.1886 91.4974 23.7067 92.7965 23.7067C94.0955 23.7067 95.2574 23.2648 95.2574 22.1029C95.2574 21.0933 94.2022 20.6819 92.7812 20.5295C90.5984 20.2705 88.5793 19.6876 88.5793 17.2914C88.5793 15.0895 90.7469 14.1905 92.7203 14.1752C94.385 14.1752 96.1107 14.6476 96.9222 16.2971L95.3184 17.1238L95.3146 17.1276Z" fill="black"/>
    <path d="M106.358 25.2533V20.9295H101.101V25.2533H99.0859V14.56H101.101V19.0667H106.358V14.56H108.358V25.2533H106.358Z" fill="black"/>
    <path d="M118.366 23.2228H112.773L111.855 25.2381H109.668L114.465 14.5447H116.663L121.459 25.2381H119.261L118.358 23.2228H118.366ZM115.569 16.7924L113.585 21.3447H117.558L115.573 16.7924H115.569Z" fill="black"/>
    <path d="M131.368 14.6057L126.617 19.7219L131.764 25.1162V25.2381H129.273L124.796 20.5028V25.2381H122.781V14.5447H124.796V19.0666L128.861 14.5447H131.368V14.6057Z" fill="black"/>
    <path d="M140.96 25.2381H132.957V14.5447H140.96V16.499H134.957V18.96H140.747V20.8381H134.957V23.2533H140.96V25.2381Z" fill="black"/>
    <path d="M154.126 21.7409V14.5447H156.141V25.2381H154.507L148.823 18.1181V25.2381H146.808V14.5447H148.442L154.126 21.7409Z" fill="#03A9F4"/>
    <path d="M168.229 19.8019C168.274 22.5067 166.625 25.2419 162.88 25.2419H158.678V14.5486H162.88C166.545 14.5486 168.183 17.1619 168.229 19.8057V19.8019ZM160.682 23.2991H162.88C165.311 23.2991 166.271 21.5276 166.225 19.7867C166.179 18.1219 165.2 16.4572 162.88 16.4572H160.682V23.3029V23.2991Z" fill="#03A9F4"/>
    <path d="M177.208 23.2228H171.616L170.697 25.2381H168.515L173.311 14.5447H175.509L180.305 25.2381H178.103L177.2 23.2228H177.208ZM174.412 16.7924L172.427 21.3447H176.4L174.416 16.7924H174.412Z" fill="#03A9F4"/>
    <path d="M17.3219 0L0 10V15.6571H3.98095V12.301L17.3219 4.5981L30.659 12.301V17.2914H20.8381V19.2838C20.8381 21.2229 19.261 22.8 17.3219 22.8V26.781C20.7695 26.781 23.68 24.4457 24.5524 21.2724H34.6438V10L17.3219 0Z" fill="#03A9F4"/>
    <path d="M30.659 27.7028L17.3219 35.4057L3.98095 27.7028V21.2724H13.8019V19.28C13.8019 17.3409 15.379 15.7638 17.3181 15.7638V11.7828C13.8705 11.7828 10.96 14.1181 10.0876 17.2914H0V30L17.3219 40L34.6438 30V22.9143H30.6629V27.699L30.659 27.7028Z" fill="#03A9F4"/>
  </svg>
</template>

<script>
export default {
  name: "MainLogo"
}
</script>

<style lang='scss' scoped>

@media screen and (max-width: 992px) {
  .header {
    .logo {
      svg {
        max-width: 145px;
        max-height: 32px;
      }
    }
  }
}

</style>
