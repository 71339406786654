import { createWebHistory, createRouter } from "vue-router";
import {checkCurrentUser} from "@/services/helpers";
import storage from "@/store";

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import(/* webpackChunkName: "HomePage" */ '../pages/HomePage.vue'),
    },
    {
        path: '/dashboard',
        name: 'UserDashboard',
        component: () => import(/* webpackChunkName: "UserDashboard" */ '../pages/UserDashboard.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/account',
        name: 'UserAccount',
        component: () => import(/* webpackChunkName: "UserDashboard" */ '../pages/UserDashboard.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/standard-terms',
        name: 'StandardTerms',
        component: () => import(/* webpackChunkName: "UserDashboard" */ '../pages/StandardTerms.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/archive',
        name: 'UserArchiveDashboard',
        component: () => import(/* webpackChunkName: "UserDashboard" */ '../pages/UserArchiveDashboard.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reset-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "UserDashboard" */ '../pages/ForgotPassword.vue'),
    },
    {
        path: '/support',
        name: 'HNSupport',
        component: () => import(/* webpackChunkName: "UserDashboard" */ '../pages/HNSupport.vue'),
    },
    {
        path: '/nda-create',
        name: 'CreateNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/CreateNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/nda-preview',
        name: 'PreviewNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/PreviewNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/new-user-nda-preview',
        name: 'PreviewNDAByEmail',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/PreviewNDAByEmail.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/nda-preview/:id',
        name: 'PreviewCreatedNDA',
        props: (route) => {
            const id = Number.parseInt(route.params.id, 10);
            if (Number.isNaN(id)) {
                return 0;
            }
            return { id };
        },
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/PreviewCreatedNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/second-step',
        name: 'SecondStepOptimised',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/SecondStepOptimised.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../pages/UserProfile.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/nda-send/:id',
        name: 'SendNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/SendNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/nda-send-joinder/:id',
        name: 'SendJoinderNDA',
        meta: {
            is_joinder: true,
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/SendNDA.vue'),
    },
    {
        path: '/sign/:id',
        name: 'SignNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/SignNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/sign-joined-nda/:id',
        name: 'SignJoinedNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/SignNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/changed-nda',
        name: 'SignChangedNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/SignChangedNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/signed', //todo remove SignedNDA.vue component later
        name: 'SignedNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/PreviewNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/nda/:id',
        name: 'ViewNDA',
        props: (route) => {
            const id = Number.parseInt(route.params.id, 10);
            if (Number.isNaN(id)) {
                return 0;
            }
            return { id };
        },
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/ViewNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/decline/:id',
        name: 'DeclineNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/DeclineNDA.vue'),
    },
    {
        path: '/requested-changes-nda',
        name: 'RequestedChangesNdaRedirect',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/NDARedirects.vue'),
        meta: {
            nda_changed_by_user: true
        },
    },
    {
        path: '/requested-changes-nda/:id',
        name: 'RequestedChangesNdaRedirectId',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/NDARedirects.vue'),
        meta: {
            nda_changed_by_user: true
        },
    },
    {
        path: '/signed-nda-notice/:nda_id/:user_id',
        name: 'SignedNdaNotice',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/NDARedirects.vue'),
        meta: {
            signed_nda_notice: true
        },
    },
    {
        path: '/respond-changes-nda/:id/:user_id',
        name: 'RespondChangesNdaRedirect',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/NDARedirects.vue'),
        meta: {
            nda_changed_by_owner: true
        },
    },
    {
        path: '/receive/:id',
        name: 'ReceiveNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/ReceiveNDA.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/join-member',
        name: 'JoinMemberNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/ReceiveNDA.vue'),
    },
    {
        path: '/forward/:id',
        name: 'ForwardNDA',
        component: () => import(/* webpackChunkName: "AdminHome" */ '../components/NDA/ReceiveNDA.vue'),
        meta: {
            forward_nda: true,
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    storage.commit('showLoader');
    window.scrollTo(0, 0);
    const user = checkCurrentUser();
    if (user && user.id && to.name === "HomePage") {
        next({name: 'UserDashboard'})
    }
    if (to.meta.requiresAuth && (!user || !user.id)) {
        if (to.name === "ReceiveNDA") {
            next();
        } else {
            next({name: 'HomePage'})
        }
    }
    next();
    storage.commit('hideLoader');
});

export default router;
