<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0007 17C8.20854 17 4.71455 15.0854 2.12813 12C4.71452 8.91478 8.2076 7 11.9994 7C15.7915 7 19.2855 8.91457 21.8719 12C19.2855 15.0852 15.7924 17 12.0007 17ZM11.9994 5C7.47937 5 3.44346 7.29456 0.55522 10.7633C-0.0418213 11.4803 -0.0418558 12.5196 0.555187 13.2367C3.44365 16.7059 7.48053 19 12.0007 19C16.5206 19 20.5565 16.7054 23.4448 13.2367C24.0418 12.5197 24.0419 11.4804 23.4448 10.7633C20.5564 7.29414 16.5195 5 11.9994 5ZM10.7751 10.7761C11.4518 10.0994 12.5488 10.0994 13.2254 10.7761C13.902 11.4527 13.902 12.5497 13.2254 13.2263C12.5488 13.9029 11.4518 13.9029 10.7751 13.2263C10.0985 12.5497 10.0985 11.4527 10.7751 10.7761ZM14.6396 9.36184C13.1819 7.90417 10.8186 7.90417 9.36093 9.36184C7.90326 10.8195 7.90326 13.1828 9.36093 14.6405C10.8186 16.0982 13.1819 16.0982 14.6396 14.6405C16.0973 13.1828 16.0973 10.8195 14.6396 9.36184Z" fill="#03A9F4"/>
  </svg>
</template>

<script>
export default {
  name: "EyeOn"
}
</script>

<style scoped>

</style>
