<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 0L2.00858 7.5V11.7429H4.9943V9.22571L15 3.44857L25.0029 9.22571V12.9686H17.6372V14.4629C17.6372 15.9171 16.4543 17.1 15 17.1V20.0857C17.5857 20.0857 19.7686 18.3343 20.4229 15.9543H27.9914V7.5L15 0Z" fill="#03A9F4"/>
    <path d="M25.0029 20.7772L15 26.5543L4.9943 20.7772V15.9543H12.36V14.46C12.36 13.0057 13.5429 11.8229 14.9972 11.8229V8.83716C12.4114 8.83716 10.2286 10.5886 9.5743 12.9686H2.00858V22.5L15 30L27.9914 22.5V17.1857H25.0057V20.7743L25.0029 20.7772Z" fill="#03A9F4"/>
  </svg>
</template>

<script>
export default {
  name: "SimpleLogo"
}
</script>

<style scoped>

</style>
