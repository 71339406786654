<template>
  <svg width="182" height="40" viewBox="0 0 182 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.8954 25.2534V20.9296H44.6383V25.2534H42.623V14.5601H44.6383V19.0667H49.8954V14.5601H51.8954V25.2534H49.8954Z" fill="black"/>
    <path d="M61.9032 23.223H56.3108L55.3927 25.2383H53.2061L58.0022 14.5449H60.2003L64.9965 25.2383H62.7984L61.8956 23.223H61.9032ZM59.107 16.7925L57.1222 21.3449H61.0956L59.1108 16.7925H59.107Z" fill="black"/>
    <path d="M73.6326 21.7411V14.5449H75.6478V25.2383H74.0135L68.3297 18.1183V25.2383H66.3145V14.5449H67.9487L73.6326 21.7411Z" fill="black"/>
    <path d="M87.7355 19.8019C87.7812 22.5067 86.1317 25.2419 82.387 25.2419H78.1851V14.5486H82.387C86.0517 14.5486 87.6898 17.1619 87.7355 19.8057V19.8019ZM80.1889 23.2991H82.3908C84.8212 23.2991 85.7812 21.5276 85.7355 19.7867C85.6898 18.1219 84.7108 16.4572 82.3908 16.4572H80.1889V23.3029V23.2991Z" fill="black"/>
    <path d="M96.0289 17.1277C95.6632 16.5182 94.6537 15.9353 93.4918 15.9353C91.9946 15.9353 91.2784 16.5601 91.2784 17.3562C91.2784 18.2896 92.3794 18.5486 93.6632 18.701C95.8956 18.9753 97.9718 19.5582 97.9718 22.1067C97.9718 24.4915 95.8651 25.5124 93.4803 25.5124C91.2975 25.5124 89.6137 24.842 88.8213 22.8839L90.5013 22.0115C90.9737 23.1886 92.2118 23.7067 93.5108 23.7067C94.8099 23.7067 95.9718 23.2648 95.9718 22.1029C95.9718 21.0934 94.9165 20.682 93.4956 20.5296C91.3127 20.2705 89.2937 19.6877 89.2937 17.2915C89.2937 15.0896 91.4613 14.1905 93.4346 14.1753C95.0994 14.1753 96.8251 14.6477 97.6365 16.2972L96.0327 17.1239L96.0289 17.1277Z" fill="black"/>
    <path d="M107.073 25.2534V20.9296H101.816V25.2534H99.8003V14.5601H101.816V19.0667H107.073V14.5601H109.073V25.2534H107.073Z" fill="black"/>
    <path d="M119.08 23.223H113.488L112.569 25.2383H110.383L115.179 14.5449H117.377L122.173 25.2383H119.975L119.072 23.223H119.08ZM116.284 16.7925L114.299 21.3449H118.272L116.288 16.7925H116.284Z" fill="black"/>
    <path d="M132.082 14.6059L127.332 19.7221L132.478 25.1164V25.2383H129.987L125.511 20.503V25.2383H123.496V14.5449H125.511V19.0668L129.576 14.5449H132.082V14.6059Z" fill="black"/>
    <path d="M141.675 25.2383H133.671V14.5449H141.675V16.4992H135.671V18.9602H141.461V20.8383H135.671V23.2535H141.675V25.2383Z" fill="black"/>
    <path d="M154.84 21.7411V14.5449H156.855V25.2383H155.221L149.537 18.1183V25.2383H147.522V14.5449H149.156L154.84 21.7411Z" fill="#03A9F4"/>
    <path d="M168.943 19.8019C168.989 22.5067 167.339 25.2419 163.594 25.2419H159.393V14.5486H163.594C167.259 14.5486 168.897 17.1619 168.943 19.8057V19.8019ZM161.396 23.2991H163.594C166.025 23.2991 166.985 21.5276 166.939 19.7867C166.894 18.1219 165.914 16.4572 163.594 16.4572H161.396V23.3029V23.2991Z" fill="#03A9F4"/>
    <path d="M177.922 23.223H172.329L171.411 25.2383H169.229L174.025 14.5449H176.223L181.019 25.2383H178.817L177.914 23.223H177.922ZM175.126 16.7925L173.141 21.3449H177.114L175.129 16.7925H175.126Z" fill="#03A9F4"/>
    <path d="M18.0363 0L0.714355 10V15.6571H4.69531V12.301L18.0363 4.5981L31.3734 12.301V17.2914H21.5525V19.2838C21.5525 21.2229 19.9753 22.8 18.0363 22.8V26.781C21.4839 26.781 24.3944 24.4457 25.2667 21.2724H35.3582V10L18.0363 0Z" fill="#03A9F4"/>
    <path d="M31.3734 27.703L18.0363 35.4058L4.69531 27.703V21.2725H14.5163V19.2801C14.5163 17.3411 16.0934 15.7639 18.0325 15.7639V11.783C14.5848 11.783 11.6744 14.1182 10.802 17.2915H0.714355V30.0001L18.0363 40.0001L35.3582 30.0001V22.9144H31.3772V27.6991L31.3734 27.703Z" fill="#03A9F4"/>
  </svg>
</template>

<script>
export default {
  name: "SmallLogo",

}
</script>

<style  scoped>
</style>
