<template>
  <svg width="145" height="32" viewBox="0 0 145 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.3448 20.2027V16.7436H35.139V20.2027H33.5269V11.648H35.139V15.2533H39.3448V11.648H40.9448V20.2027H39.3448Z" fill="black"/>
    <path d="M48.9509 18.5783H44.477L43.7425 20.1905H41.9932L45.8301 11.6358H47.5886L51.4255 20.1905H49.6671L48.9448 18.5783H48.9509ZM46.7139 13.4339L45.1261 17.0758H48.3048L46.717 13.4339H46.7139Z" fill="black"/>
    <path d="M58.3345 17.3928V11.6358H59.9466V20.1905H58.6392L54.0922 14.4945V20.1905H52.48V11.6358H53.7874L58.3345 17.3928Z" fill="black"/>
    <path d="M69.6167 15.8415C69.6533 18.0053 68.3337 20.1935 65.3378 20.1935H61.9763V11.6389H65.3378C68.2697 11.6389 69.5801 13.7295 69.6167 15.8446V15.8415ZM63.5794 18.6392H65.3409C67.2853 18.6392 68.0533 17.2221 68.0167 15.8293C67.9801 14.4975 67.1969 13.1657 65.3409 13.1657H63.5794V18.6423V18.6392Z" fill="black"/>
    <path d="M76.2514 13.7021C75.9589 13.2145 75.1513 12.7482 74.2217 12.7482C73.024 12.7482 72.4511 13.248 72.4511 13.885C72.4511 14.6316 73.3318 14.8389 74.3589 14.9608C76.1448 15.1802 77.8057 15.6465 77.8057 17.6854C77.8057 19.5932 76.1204 20.4099 74.2126 20.4099C72.4663 20.4099 71.1193 19.8735 70.4854 18.3071L71.8294 17.6092C72.2073 18.5509 73.1977 18.9654 74.237 18.9654C75.2762 18.9654 76.2057 18.6118 76.2057 17.6823C76.2057 16.8747 75.3615 16.5455 74.2248 16.4236C72.4785 16.2164 70.8633 15.7501 70.8633 13.8332C70.8633 12.0716 72.5974 11.3524 74.176 11.3402C75.5078 11.3402 76.8884 11.7181 77.5375 13.0377L76.2545 13.6991L76.2514 13.7021Z" fill="black"/>
    <path d="M85.0865 20.2027V16.7436H80.8807V20.2027H79.2686V11.648H80.8807V15.2533H85.0865V11.648H86.6865V20.2027H85.0865Z" fill="black"/>
    <path d="M94.6926 18.5783H90.2187L89.4842 20.1905H87.7349L91.5718 11.6358H93.3303L97.1672 20.1905H95.4088L94.6865 18.5783H94.6926ZM92.4556 13.4339L90.8678 17.0758H94.0465L92.4587 13.4339H92.4556Z" fill="black"/>
    <path d="M105.094 11.6846L101.294 15.7775L105.411 20.0929V20.1905H103.418L99.837 16.4023V20.1905H98.2249V11.6358H99.837V15.2533L103.089 11.6358H105.094V11.6846Z" fill="black"/>
    <path d="M112.768 20.1905H106.365V11.6358H112.768V13.1992H107.965V15.168H112.597V16.6705H107.965V18.6027H112.768V20.1905Z" fill="black"/>
    <path d="M123.301 17.3928V11.6358H124.913V20.1905H123.605L119.058 14.4945V20.1905H117.446V11.6358H118.753L123.301 17.3928Z" fill="#03A9F4"/>
    <path d="M134.583 15.8415C134.619 18.0053 133.3 20.1935 130.304 20.1935H126.942V11.6389H130.304C133.236 11.6389 134.546 13.7295 134.583 15.8446V15.8415ZM128.545 18.6392H130.304C132.248 18.6392 133.016 17.2221 132.98 15.8293C132.943 14.4975 132.16 13.1657 130.304 13.1657H128.545V18.6423V18.6392Z" fill="#03A9F4"/>
    <path d="M141.766 18.5783H137.292L136.558 20.1905H134.812L138.648 11.6358H140.407L144.244 20.1905H142.482L141.76 18.5783H141.766ZM139.529 13.4339L137.941 17.0758H141.12L139.532 13.4339H139.529Z" fill="#03A9F4"/>
    <path d="M13.8575 0L0 8V12.5257H3.18476V9.84076L13.8575 3.67848L24.5272 9.84076V13.8331H16.6705V15.427C16.6705 16.9783 15.4088 18.24 13.8575 18.24V21.4248C16.6156 21.4248 18.944 19.5566 19.6419 17.0179H27.715V8L13.8575 0Z" fill="#03A9F4"/>
    <path d="M24.5272 22.1623L13.8575 28.3246L3.18476 22.1623V17.0179H11.0415V15.424C11.0415 13.8727 12.3032 12.611 13.8545 12.611V9.42627C11.0964 9.42627 8.768 11.2945 8.0701 13.8331H0V24L13.8575 32L27.715 24V18.3314H24.5303V22.1592L24.5272 22.1623Z" fill="#03A9F4"/>
  </svg>
</template>

<script>
export default {
  name: "SmallModalLogo"
}
</script>

<style scoped>

</style>
