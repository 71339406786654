<template>
  <ModalsTemplate
      key="logOutPopUp"
      :modal-id='"logOutPopUp"'
  >
    <template v-slot:slot-modal-title>
      Log Out
    </template>
    <template v-slot:slot-modal-form>
      <div class='nda-success align-items-center form flex flex-column gap-20p'>
        <div class='massage'>
          Are you sure you want to log out?
        </div>
        <div class='actions w_100 flex gap-15p justify-content-between align-items-center'>
          <button class='btn bold w_100 white' @click='cancelLogOut'>Cancel</button>
          <button class='btn bold w_100 light-blue' @click='logOut'>Ok</button>
        </div>
      </div>
    </template>
  </ModalsTemplate>
</template>

<script>
import ModalsTemplate from "@/components/Layouts/ModalsTemplate";
import {modalIds} from "@/services/enums";
import {mapMutations} from "vuex";
export default {
  name: "LogOutPopUp",
  components: {
    ModalsTemplate,
  },
  methods: {
    ...mapMutations([
      'setCloseModal',
      'showLoader',
      'hideLoader',
      'setLoggedUser'
    ]),
    async logOut() {
      this.showLoader();
      delete localStorage.user_jwt;
      delete localStorage.stored_user;
      await this.setLoggedUser({})
      this.hideLoader();
      await this.setCloseModal(modalIds.logOutPopUp)
      await this.$router.push({path: '/'})
    },
    async cancelLogOut() {
      await this.setCloseModal(modalIds.logOutPopUp)
    }
  }
}
</script>

<style scoped>

</style>
