<template>
  <div class='land-block'>
   <div class='flex flex-column gap-40p'>
     <div class='main-title top'>Used and loved by the Independent Sponsor and Search Fund community</div>
     <div class='partners flex justify_content_between align-items-center'>
       <div class='partner md-visible' v-for='partner in our_partners' :key='partner.title'>
         <img :src='resolve_img_url(partner.img_path)' alt='partner logo'>
       </div>
       <swiper
           :slides-per-view="2"
           :space-between="45"
           :centeredSlides="true"
           :loop="true"
       >
         <swiper-slide class='partner sm-visible' v-for='partner in our_partners' :key='partner.title'>
           <img :src='resolve_img_url(partner.img_path)' alt='partner logo'>
         </swiper-slide>
       </swiper>

     </div>
     <div class='main-title'>Emko spent 90% less time on NDAs and reached 5x potential equity sources</div>
     <div class='founder flex flex-column gap-30p'>
       <div class='icon'><img src='@/assets/images/landing/founder.png' alt='founder'></div>
       <div class='info'>“We used to spend hours on NDAs, the back and forth, the wasted time. Handshake NDA makes it so simple to send NDAs, get our deals into potential funding sources, and raise capital faster.”</div>
       <div class='position'><span class='name'>Josh K</span> / Founder, EC</div>
     </div>
   </div>
  </div>
</template>

<script>
import "swiper/scss";
import { Swiper, SwiperSlide } from 'swiper/vue';

import ourPartners from "@/store/landing_partners.json"
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  name: "OurPartners",
  methods: {
    resolve_img_url (path) {
      return require(`@/assets/${path}`)
    },
  },
  computed: {
    our_partners() {
      return ourPartners;
    }
  }
}
</script>

<style lang='scss' scoped>
.land-block {
  .main-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding-bottom: 40px;
  }
  .founder {
    padding: 50px 30px 30px;
    background: #EFF2FA;
    border: 1px solid #D5E1F3;
    border-radius: 20px;
    text-align: center;
    position: relative;
    margin-top: 15px;
    .icon {
      img {
        position: absolute;
        max-width: 114px;
        top: -65px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    .position {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7191BF;
      .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #013349;
      }
    }
  }
  .partners {
    padding-bottom: 40px;
    .partner {
      img {
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .partners {
      padding-bottom: 10px;
    }
    .founder {
      padding: 55px 15px 15px;
      gap: 20px;
    }
    .info {
      font-size: 13px;
      line-height: 19px;
    }
    .main-title {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      &.top {
        padding-bottom: 0;
      }
    }
  }
}
</style>
