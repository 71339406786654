<template>
  <MainLayout>
    <div class='home-landing w_100'>
      <div class='blocks flex flex_column gap-80p'>
        <div class='item m-order-1'>
          <TopBanner/>
        </div>
        <div class='item m-order-1'>
          <OurFeatures/>
        </div>
        <div class='item m-order-3'>
          <FeaturesSlider/>
        </div>
        <div class='item full_width m-order-2'>
          <OurAdventage/>
        </div>
        <div class='item m-order-4'>
          <OurPartners/>
        </div>
        <div class='item m-order-4'>
          <FallInLove/>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import TopBanner from "@/components/Landing/TopBanner";
import OurFeatures from "@/components/Landing/OurFeatures";
import FeaturesSlider from "@/components/Landing/FeaturesSlider";
import OurAdventage from "@/components/Landing/OurAdventage";
import OurPartners from "@/components/Landing/OurPartners";
import FallInLove from "@/components/Landing/FallInLove";

export default {
  name: "HomePage",
  components: {
    MainLayout,
    TopBanner,
    OurFeatures,
    FeaturesSlider,
    OurAdventage,
    OurPartners,
    FallInLove
  }
}
</script>

<style lang='scss' scoped>
.home-landing {
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 100%;
  .blocks > .item {
    padding: 0 40px;
    &.full_width {
      padding: 0;
    }
  }
  @media screen and (max-width: 992px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .m-order-1 {
      order: 1;
    }
    .m-order-2 {
      order: 2;
    }
    .m-order-3 {
      order: 3;
    }
    .m-order-4 {
      order: 4;
    }
    .blocks {
      gap: 40px;
      & > .item {
        padding: 0 22px;

        &.full_width {
          padding: 0;
        }
      }
    }
  }
}


</style>
