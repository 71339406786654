<template>
  <div class='land-block'>
    <div class='w_100 features flex gap-30p'>
      <div class='item w_100' v-for='feature in features' :key='feature.title'>
        <div class='inner'>
          <div class='icon' v-html='feature.icon'></div>
          <div class='flex flex_column gap-20p'>
            <div class='title'>{{feature.title}}</div>
            <div class='info'>{{feature.info}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import landingTopFeatures from "@/store/landing_top_features.json";
export default {
  name: "OurFeatures",
  data() {
    return {
      features: landingTopFeatures
    }
  }
}
</script>

<style lang='scss' scoped>
.features {
  margin-top: 65px;
  .item {
    background: #EFF2FA;
    padding: 70px 30px 30px 30px;
    border: 1px solid #D5E1F3;
    border-radius: 20px;
    position: relative;
    text-align: center;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }
    .info {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .icon {
      position: absolute;
      top: -65px;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: space-around;
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 57px;
    .item {
      padding: 55px 15px 15px;
      .title {
        font-weight: 700;
        font-size: 19px;
        line-height: 24px;
      }
      .info {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
      }
      .inner>.flex_column {
        gap: 10px;
      }
    }
    .icon {
      max-width: 79px;
      top: -55px;
    }
  }
}
</style>
