import http from './http_client';
import storage from '@/store/index';
import {errorMessage} from '@/services/messages';
import {timeToISO} from "@/services/time.helper";

export const baseErrHandler = async (callback) => {
    try {
        storage.commit('showLoader');
        await callback();
    } catch (e) {
        console.log(e);
        errorMessage(e?.response?.data?.error?.message);
    } finally {
        storage.commit('hideLoader');
    }
};

export const registerUser = async (formData) => {
    const response = await http.post(`${http.apiUrl()}auth/register`, formData);
    const { user, access_token, error } = response?.data || {};
    if (error) {
        return {error};
    }
    return { user, access_token };
};

export const forgotPassword = async (formData) => {
    const response = await http.post(`${http.apiUrl()}auth/forgot-password`, formData);
    const {success} = response.data || {}
    return {...success};
};

export const resetPassword = async (formData) => {
    const response = await http.post(`${http.apiUrl()}auth/reset-password`, formData);
    const { user, access_token } = response?.data?.data || {}
    return { user, access_token };
};


export const updatePassword = async (formData) => {
    const response = await http.postAuth(`${http.apiUrl()}auth/update-password`, formData);
    const { data } = response?.data || {}
    return { data };
};

export const logIn = async (data) => {
    const response = await http.post(`${http.apiUrl()}auth/login`, data);
    const { user, access_token } = response?.data || {};
    return { user, access_token };
}

export const createNDA = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/create`, data);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const getNDA = async (id) => {
    const response = await http.get(`${http.apiUrl()}nda/${id}`);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const getUsersNameByEmail = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}user/user-name`, data);
    const users  = response?.data.data.result || {};
    return { ...users };
}


export const getSignNDA = async (id) => { //todo Use it later to get changed nda data
    const response = await http.getAuth(`${http.apiUrl()}nda/sign/${id}`);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const getNDAs = async (id) => {
    const response = await http.getAuth(`${http.apiUrl()}nda/user-ndas/${id}`);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const getAllUserNDAs = async (id) => {
    const response = await http.getAuth(`${http.apiUrl()}nda/all-user-ndas/${id}`);
    const ndas  = response?.data.data || {};
    return { ...ndas };
}

export const receiveNDA = async (id) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/receive/${id}`, {created_date: timeToISO(Date.now())});
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const joinNDA = async (formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/join`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const sendNDA = async (id, formData) => {
    formData.append('created_date', timeToISO(Date.now()));
    const response = await http.postAuth(`${http.apiUrl()}nda/send/${id}`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const sendJoinderNDA = async (id, formData) => {
    formData.append('created_date', timeToISO(Date.now()));
    const response = await http.postAuth(`${http.apiUrl()}nda/send-joinder/${id}`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const forwardNDA = async (id, formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/forward/${id}`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const signUserNdaNDA = async (formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/sign-user-nda`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const signNDA = async (id, formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/sign/${id}`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const modifyNDA = async (id, data) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/modify/${id}`, data);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const getUserNDAs = async (
            items_per_page = 10,
            offset = 0,
            key = 'createdAt',
            sort = true,
            is_archived = false
) => {
    let parameters =
        `?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}&archived=${is_archived}`;
    const response = await http.getAuth(`${http.apiUrl()}nda/all-nda${parameters}`);
    const user_ndas  = response?.data.data || {};
    return user_ndas;
}

export const getArchvedUserNDAs = async (
            items_per_page = 10,
            offset = 0,
            key = '',
            sort = true,
            is_archived = false
) => {
    let parameters =
        `?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}&archived=${is_archived}`;
    const response = await http.getAuth(`${http.apiUrl()}nda/all-archived-nda${parameters}`);
    const user_ndas  = response?.data.data || {};
    return user_ndas;
}

export const getArchivedUserNDAs = async (
            id,
            items_per_page = 10,
            offset = 0,
            key = '',
            sort = true
) => {
    let parameters =
        `?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}`;
    const response = await http.getAuth(`${http.apiUrl()}nda/archived-user-ndas/${id}/${parameters}`);
    const user_ndas  = response?.data.data || {};
    return user_ndas;
}

export const getSignedNDAs = async (
            id,
            items_per_page = 10,
            offset = 0,
            key = '',
            sort = true,
            is_archived = false
) => {
    let parameters =
        `?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}&archived=${is_archived}`;
    const response = await http.getAuth(`${http.apiUrl()}nda/signed-ndas/${id}${parameters}`);
    const user_ndas  = response?.data.data || {};
    return user_ndas;
}

export const getDeclinedNDAs = async (
            id,
            items_per_page = 10,
            offset = 0,
            key = '',
            sort = true,
            is_archived = false
) => {
    let parameters =
        `?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}&archived=${is_archived}`;
    const response = await http.getAuth(`${http.apiUrl()}nda/declined-ndas/${id}${parameters}`);
    const user_ndas  = response?.data.data || {};
    return user_ndas;
}

export const getJoinedNDAs = async (
            id,
            items_per_page = 10,
            offset = 0,
            key = '',
            sort = true,
            is_archived = false
) => {
    let parameters =
        `?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}&archived=${is_archived}`;
    const response = await http.getAuth(`${http.apiUrl()}nda/joined-ndas/${id}${parameters}`);
    const user_ndas  = response?.data.data || {};
    return user_ndas;
}

export const getWaitingForSignNDAs = async (
    id,
    items_per_page = 10,
    offset = 0,
    key = '',
    sort = true,
    is_archived = false
) => {
    let parameters =
        `?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}&archived=${is_archived}`;
    const response = await http.getAuth(`${http.apiUrl()}nda/waiting-for-sign-ndas/${id}${parameters}`);
    const user_ndas  = response?.data.data || {};
    return user_ndas;
}

export const bulkNDAsReminder = async (id) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/bulk-sign-reminder/${id}`);
    const success  = response?.data.data || {};
    return success;
}

export const NDAReminder = async (nda_id, user_email) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/single-sign-reminder`, {nda_id, user_email});
    const success  = response?.data.data || {};
    return success;
}

export const restoredNDAEmail = async (nda_id, user_id = null, user_email = null) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/restored-nda-email`, {nda_id, user_id, user_email});
    const success  = response?.data.data || {};
    return success;
}

export const getReceivedNDAs = async (key = '', sort = true, archived= false) => {
    let parameters = `?key=${key}&sort=${sort}&archived=${archived}`;
    const response = await http.getAuth(`${http.apiUrl()}user/nda/received${parameters}`);
    const ndas  = response?.data.data || {};
    return { ...ndas };
}

export const updateUser = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}user/update`, data);
    const user  = response?.data.data || {};
    return { ...user };
}

export const declineNDA = async (id, formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/decline/${id}`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const declineUserNDA = async (id) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/user/decline/${id}`);
    const {success = false} = response?.data || {};
    return {success};
}

export const updateUserNda = async (formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/user_nda_update`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const archiveUserNda = async (formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/user_nda_archive`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const restoreUserNda = async (formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/user_nda_archive`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const archiveNda = async (formData) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/archive`, formData);
    const nda  = response?.data.data || {};
    return { ...nda };
}

export const sortNDAByCompany = async (id,
                                       key,
                                       sort,
                                       items_per_page = 10,
                                       offset = 0,
                                       call_requested = false
) => {
    const response = await http.getAuth(`${http.apiUrl()}nda/fetch/${id}?items_per_page=${items_per_page}&offset=${offset}&key=${key}&sort=${sort}&call_requested=${call_requested}`);
    const ndas  = response?.data.data || {};
    return { ...ndas };
}

export const getUserNda = async (user_id, nda_id) => {
    const response = await http.getAuth(`${http.apiUrl()}nda/user-nda/${nda_id}/${user_id}`);
    const ndas  = response?.data.data || {};
    return { ...ndas };
}

export const getUserNdaByEmail = async (user_email, nda_id) => {
    const response = await http.getAuth(`${http.apiUrl()}nda/user-nda-by-email/${nda_id}/${user_email}`);
    const ndas  = response?.data.data || {};
    return { ...ndas };
}

export const getJoinderNda = async (user_id, nda_id) => {
    const response = await http.get(`${http.apiUrl()}nda/joinder-nda/${nda_id}/${user_id}`);
    const ndas  = response?.data.data || {};
    return { ...ndas };
}

export const getUserByEmailHash = async (hash) => {
    const response = await http.postAuth(`${http.apiUrl()}user/hash/${hash}`);
    const user = response?.data.data.user || {};
    return { ...user };
}

export const getNdaIdByHash = async (hash) => {
    const response = await http.postAuth(`${http.apiUrl()}nda/get-id-by-hash/${hash}`);
    const {nda_id} = response?.data.data || {};
    return nda_id;
}

export const getJoinderByEmailHash = async (hash) => {
    const response = await http.postAuth(`${http.apiUrl()}user/joinder-hash/${hash}`);
    const {user, decoded} = response?.data.data.result || {};
    return { user, decoded };
}
