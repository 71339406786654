import HomePage from "@/pages/HomePage";

export const isNotValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{1,20}\.)+[a-zA-Z]{2,6}))$/;
    return !re.test(email);
};
export const goHome = async () => {
    return await this.$router.push({name: HomePage})
}

export const saveObjectToStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}


export const savePropsToStorage = (key, data) => {
    localStorage.setItem(key, data);
}

export const deleteFromStorage = (key) => {
    delete localStorage[key];
}

export const clearStatusText = (status) => {
    let cleared = status.replaceAll('_', ' ')
    return cleared;
}

export const isEmptyObj = (obj) => {
    return Object.keys(obj).length === 0;
}
export const formatFileSize = (val) => {
    return parseFloat(val / (1024*1024)).toFixed(2);
}

export const stringToObj = (keys, val) => {
    var tempObject = {};
    var container = tempObject;
    keys.split('.').map((k, i, values) => {
        container = (container[k] = (i == values.length - 1 ? val : {}))
    });
    return tempObject;
}

export const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export const checkEmptyObjDeep = (obj) => {

    for(let key in obj){
        if(obj[key] instanceof Object === true){
            if(checkEmptyObjDeep(obj[key]) === false) return false;
        }
        else{
            if(obj[key].length !== 0) return false;
        }
    }
    return true;
}

export const mergeDeep = (target, source) => {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

export const checkCurrentUser = () => {
    let user;
    try{
        user = JSON.parse(localStorage.getItem('stored_user'))
    }catch (e) {
        console.log(e);
    }
    if (!user?.id) {
        delete localStorage.stored_user;
        delete localStorage.user_jwt;
    }
    return user;
};

export const removeModalArtifacts = async () => {
    await document.body.classList.remove('modal-open')
    await document.body.removeAttribute('style')
};
