<template>
  <!-- need to be deleted -->
  <div class="dropdown" id="custom-dropdown-selector" :key="key_cust">
    <div
        @click="toggleDropdown"
        :class="titleStyle"
        class="dropdown-toggle drop_label d-flex justify-content-between active-pointer"
        :style="'widgth: ' + width + ';'"
        id="dropdownMenuTogglerAndButton"
        data-bs-toggle="dropdown"
        aria-expanded="false">
      <div class="dropdown-title d-flex align-items-center gap-18p">
        <span v-if='default_name' :class="selected_name_style">{{ default_name }}</span>
        <span v-else :class="selected_name_style">{{ this_item_view }}</span>
        <span class="pointer-arrow" style="margin-left: 10px;">
          <ArrowDownBlue v-if='is_down'/>
          <ArrowDownBlue v-else class='rotate-180'/>
        </span>
      </div>

    </div>
    <ul v-if="dropdownData.dropdownValue.length" class="dropdown-menu" :style="'width: ' + width + ' !important;'" aria-labelledby="dropdownMenuTogglerAndButton">
      <li v-for="(item, index) in dropdownData.dropdownValue"
          :key="'dropdown-item-index-' + index"
          @click="selectItem()"
          class="dropdown-item active-pointer"
          :class="returnItemStile(item)">
        <router-link class='flex align-items-center gap-11p' v-if='item.link' :to='item.link'>
          <span v-if='item.icon' class='icon' v-html='item.icon'></span>
          <span class='name'>{{selectItemView(item)}}</span>
        </router-link>
        <span class='flex align-items-center gap-11p' v-else @click='logOut'>
          <span v-if='item.icon' class='icon' v-html='item.icon'></span>
          <span class='name'>{{item.title}}</span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

import ArrowDownBlue from "@/components/SVG/ArrowDownBlue";
import {mapMutations} from "vuex";
import {modalIds} from "@/services/enums";

export default {

  name: 'CustomDropdown',
  props: {
    default_name: {
      type: String,
      default: null,
    },
    key_cust: {
      type: String,
      default: 'abcd_1-' + Math.random(),
    },
    selected_name_style: {
      type: String,
      default: 'selected_name',
    },
    selectEventEmit: {
      type: String,
      default: 'cd-selected-item',
    },
    width: {
      type: String,
      default: '100%',
    },
    titleStyle: {
      type: String,
      default: '',
    },
    itemStyle: {
      type:String,
      default: 'dropdown-item',
    },
    selectItemStile: {
      type: String,
      default: 'non-display',
    },
    pointerArrow: {
      type: Object,
      default() {
        return {
          down: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>`,
          up:`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>`,
        };
      }
    },
    dropdownData: {
      dropdownValue: [
        {
          id: 0,
          title: 'in network',
        },
        {
          id: 1,
          title: 'out of network',
        }
      ],
      dropdownSelectedItem: {
        id: 0,
        title: 'in network',
      },
      selectField: {
        type: String,
        default: 'title',
      }
    }
  },
  components: {
    ArrowDownBlue
  },
  mounted() {
   this.this_item_view = this.selectItemView(this.dropdownData.dropdownSelectedItem);
  },
  data() {
    return {
      is_down: true,
      this_item_view: null,
    }
  },

  methods: {
    ...mapMutations([
        'showLoader',
        'hideLoader',
        'setLoggedUser',
        'setOpenModal'
    ]),
    async logOut() {
      this.showLoader();
      await this.setOpenModal(modalIds.logOutPopUp);
      this.hideLoader();
    },
    selectItemView(item) {
      return item[this.dropdownData.selectField];
    },
    returnItemStile(item) {
      let stile_string = this.itemStyle;
      if (this.this_item_view === item[this.dropdownData.selectField]) {
        stile_string += ' ' + this.selectItemStile;
      }
      return stile_string;
    },
    selectItem() {
      this.clickInside();
    },
    toggleDropdown() {
      this.is_down = !this.is_down;
      if (this.is_down) {
        document.addEventListener('click', this.addClickListener);
      }
    },
    addClickListener(event) {
      const element = document.getElementById('custom-dropdown-selector');

      let isClickInsideElement = element.contains(event.target);
      if (!isClickInsideElement) {
        this.clickInside();
      }
    },
    clickInside() {
      this.is_down = !this.is_down;
      document.removeEventListener('click', this.addClickListener);
    },
  },
  watch: {},
}
</script>
<style lang="scss" scoped>
#custom-dropdown-selector {
  a {
    padding: 0;
  }
}
.page_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-toggle::after {
  display: none;
}
.drop_label {
  width: 100%;
}
.text-green {
  color: #03C982;
}
.text-item-stile {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
}
.text-item-stile-mentors {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}
.active-pointer {
  cursor: pointer;
}
.dropdown-menu {
  min-width: 149px !important;
  border: none;
  box-shadow: 0px 4px 10px rgba(24, 38, 41, 0.06);
}
.dropdown-item {
  white-space: normal;
}
.selected_name {
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #013349;
}
.selected_name_full {
  width: 80%;
}
.dropdown-title-div {
  padding: 0 20px;
  width: 100%;
}
.non-display {
  display: none;
}
.dropdown {
  .dropdown-menu {
    padding: 4px;
  }
  .dropdown-title {
    background: #FFFFFF;
    padding: 10px;
    box-shadow: 0px 4px 15px rgba(1, 84, 122, 0.1);
    border-radius: 10px;
  }
  .dropdown-title,
  .dropdown-item a,
  .dropdown-item > span{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #03A9F4;
  }
  .dropdown-item {
    padding: 10px 15px 10px 10px;
    border-radius: 6px;
    &:hover, &:active, &:focus {
      color: #0295F0;
      gap: 10px;
      isolation: isolate;
      background: #EBF9FE;
    }
  }
}
@media only screen and (max-width: 992px) {

}
</style>
