import {createStore} from "vuex";
import {checkCurrentUser} from "@/services/helpers";

export default createStore({
    state: {
        loading: false,
        openModal: null,
        closeModal: null,
        preventCloseModal: null,
        modalError: null,
        stepNumber: null,
        logged_user: {},
        nda_headers: {},
        nda_body: {},
        clear_nda: false,
        nda_files: [],
        nda_public_files: [],
        stay_this_page: false,
        sell_side_advisor: true
    },
    getters:{
        isLoading(state){
            return state.loading;
        },
        getOpenModal(state){
            return state.openModal;
        },
        getStayThisPage(state){
            return state.stay_this_page;
        },
        getNDAHeaders(state){
            return state.nda_headers;
        },
        getNDABody(state){
            return state.nda_body;
        },
        getClearNdaState(state){
            return state.clear_nda;
        },
        getNDAFiles(state){
            return state.nda_files;
        },
        getPublicNDAFiles(state){
            return state.nda_public_files;
        },
        getCloseModal(state){
            return state.closeModal;
        },
        getStepNumber(state){
            return state.stepNumber;
        },
        getModalError(state){
            return state.modalError;
        },
        getSellSideAdvisor(state){
            return state.sell_side_advisor;
        },
        getLoggedUser(state) {
            const logged_user = state.logged_user || {};
            if (!logged_user || !logged_user?.id) {
                state.logged_user = Object.assign(logged_user, (checkCurrentUser() || {}));
            }
            return state.logged_user;
        },
    },
    mutations: {
        setNDAHeaders(state, val) {
            state.nda_headers = val;
        },
        setNDABody(state, val) {
            state.nda_body = val;
        },
        setClearNdaState(state, val) {
            state.clear_nda = val;
        },
        setStayThisPage(state, val){
            state.stay_this_page = val;
        },
        setNDAFiles(state, val) {
            state.nda_files = val;
        },
        setPublicNDAFiles(state, val) {
            state.nda_public_files = val;
        },
        setOpenModal(state, val){
            state.openModal = val;
            state.closeModal = null;
        },
        setPreventCloseModal(state, val){
            state.preventCloseModal = val;
        },
        async setCloseModal(state, val){
            state.openModal = null;
            state.closeModal = val;
        },
        setStepNumber(state, val){
            state.stepNumber = val;
        },
        setModalError(state, val){
            state.modalError = val;
        },
        showLoader(state) {
            state.loading = true;
        },
        hideLoader(state) {
            state.loading = false;
        },
        setSellSideAdvisor(state, val) {
            state.sell_side_advisor = val;
        },
        setLoggedUser(state, val) {
            if (val) {
                localStorage.setItem('stored_user', JSON.stringify(val))
            }
            state.logged_user = val;
        },
    },
    actions: {},
    modules: {}
});
