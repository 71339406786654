<template>
  <div class='land-block'>
    <div class='w_100 flex top-block'>
      <div class='w_50 flex justify-content-around align-items-center'>
        <div class='inner flex flex_column justify-content-between align-items-center align-content-center text-center gap-30p'>
          <h2 class='title'>Ultimate NDA tool for Independent Sponsors and Search Funds</h2>
          <div class='sub-title'>Ultimate NDA tool for Independent Sponsors and Search Funds</div>
          <div class='btn light-blue bold' @click='openRegisterForm'>Try now for free!</div>
        </div>
      </div>
      <div class='w_50'>
        <img class='image' src='@/assets/images/landing/top_banner.svg' alt='top banner image'>
      </div>
    </div>
  </div>
</template>

<script>
import {modalIds} from "@/services/enums";
import {mapMutations} from "vuex";

export default {
  name: "TopBanner",
  methods: {
    ...mapMutations([
        'setOpenModal'
    ]),
    async openRegisterForm() {
      await this.setOpenModal(modalIds.registerForm)
    },
  }
}
</script>

<style lang='scss' scoped>
.image {
  max-width: 100%;
}
.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
.sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.btn {
  padding-right: 98px;
  padding-left: 98px;
}
@media screen and (max-width: 992px) {
  .top-block {
    flex-direction: column-reverse;
    gap: 30px;
    &>.w_50 {
      width: 100%;
    }
    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
    }
    .sub-title {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
    }
    .btn {
      width: 100%;
      padding: 12.5px;
      border-radius: 10px;
    }
  }
}
</style>
