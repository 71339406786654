<template>
  <div class='land-block'>
    <div class='flex inner align-items-center'>
      <div class='w_55 flex flex_column gap-10p'>
        <div class='title'>Save 15 hours per week on NDAs</div>
        <div class='sub-title'>The ultimate time saver, specifically designed for Private Equity</div>
        <div class='info'>*Assumes 40 NDAs per week</div>
      </div>
      <div class='w_45 flex flex_column gap-10p text-center'>
        <div class='btn light-blue bold' @click='openRegisterForm'>Start for free now</div>
        <div class='info'>*Credit card not required</div>
      </div>
    </div>
  </div>
</template>

<script>

import {modalIds} from "@/services/enums";
import {mapMutations} from "vuex";
export default {
  name: "OurAdventage",
  methods: {
    ...mapMutations([
      'setOpenModal'
    ]),
    async openRegisterForm() {
      await this.setOpenModal(modalIds.registerForm)
    },
  }
}
</script>

<style lang='scss' scoped>
.land-block {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  .inner {
    width: 70%;
    margin: auto;
    gap: 60px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }
    .sub-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .info {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7191BF;
    }
  }
  @media screen and (max-width: 992px) {
    .inner {
      width: 100%;
      flex-direction: column;
      padding: 0 22px;
      gap: 20px;
      .title {
        font-weight: 700;
        font-size: 19px;
        line-height: 24px;
      }
      .sub-title {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
      }
      .btn {
        padding: 12.5px;
        border-radius: 10px;
      }
      .info {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
      }
      & > div {
        width: 100%;
      }
    }
  }
}
</style>
