<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  },

  mounted() {
    document.title = "Handshake NDA";
  }
}
</script>

<style lang='scss'>
@import "~toastify-js/src/toastify.css";
@import "./assets/_variables.scss";
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
#app {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  background: #F5F6FB;;
  color: #013349;
  .vue-html2pdf {
    display: none;
    &.flex {
      display: none;
    }
  }
  .md-visible{
    display: block;
    &.flex {
      display: flex;
    }
  }
  .sm-visible.flex,
  .sm-visible {
    display: none;
  }
  .bold {
    font-weight: 700;
  }
  input {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(1, 84, 122, 0.07);
    border-radius: 8px;
    border: 0px;
    height: 44px;
    padding-left: 14px;
    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7191BF;
    }
    &[type="checkbox"] {
      position: relative;
      border-radius: 4px;
      width: 100%;
      max-width: 20px;
      height: 20px;
      visibility: hidden;
      &:checked {
        background: transparent;
        accent-color: transparent;
        position: relative;
        visibility: hidden;
        &:before {
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          background-image: url("@/assets/images/svg/checked.svg");
          background-repeat: no-repeat;
          visibility: visible;
        }
        &:after {
          visibility: hidden;
        }
      }
      &:after {
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 20px;
        height: 20px;
        content: '';
        background-image: url("@/assets/images/svg/cbox.svg");
        opacity: 1;
        visibility: visible;
      }
    }
    &[type="radio"] {
      position: relative;
      width: 20px;
      max-width: 20px;
      height: 20px;
      visibility: hidden;
      &:disabled {
        &:after {
          background-image: url("@/assets/images/svg/radio_disabled.svg");
        }
        &:disabled {
          &:checked {
            &:before {
              background-image: url("@/assets/images/svg/radio_checked_disabled.svg");
            }
          }
        }
      }
      &:after {
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 20px;
        height: 20px;
        content: '';
        background-image: url("@/assets/images/svg/radio.svg");
        visibility: visible;
      }
      &:checked {
        &:before {
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0;
          margin: auto;
          width: 20px;
          height: 20px;
          content: '';
          background-image: url("@/assets/images/svg/radio_checked.svg");
          visibility: visible;
        }
        &:after {
          visibility: hidden;
        }
      }
    }
  }
  textarea {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(1, 84, 122, 0.07);
    border-radius: 8px;
    border: 0px;
    padding-left: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    height: auto;
    resize: none;
    &:disabled {
      background: #E9EFF9;
      color: #7191BF;
    }
  }
  .form {
    textarea {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 15px rgba(1, 84, 122, 0.07);
      border-radius: 8px;
      border: 0px;
      padding-left: 14px;
      padding-top: 14px;
      padding-bottom: 14px;
      resize: none;
    }
    .input_group {
      .info {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-top: 6px;
      }
    }
  }
  .title {

  }
  .form_label,
  .form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7191BF;
  }
  .cbox-form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .sub-title-semi {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .sub-title-u-bold {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  .cp {
    cursor: pointer;
  }

  .flex {
    display: flex;
  }
  .link {
    font-weight: 400;
    color: rgba(3, 169, 244, 1);
    text-decoration: none;
    &:hover, &:focus, &:active {
      color: rgba(2, 149, 240, 1);
    }
  }
  .bold-link {
    font-weight: 700;
    color: rgba(3, 169, 244, 1);
    text-decoration: none;
    &:hover, &:focus, &:active {
      color: rgba(2, 149, 240, 1);
    }
  }
  .modal_body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .form-error {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FF0800;
    padding-top: 6px;
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  .fake-tel-input {
    position: relative;
    width: 100%;
  }
  #fakeTelInput {
    position: absolute;
    left: 90px;
    width: calc(100% - 90px);
    top: 0px;
    z-index: 10;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .vue-tel-input {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(1, 84, 122, 0.07);
    border-radius: 8px;
    border: 0;
    .vti__input {
      display: none;
    }
  }
  #registerForm {
    .modal-dialog {
      border-radius: 10px;
    }
    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7191BF;
    }
    .vue-tel-input {
      box-shadow: 0px 2px 15px rgba(1, 84, 122, 0.07);
      border-radius: 8px;
      border: 0px;
      height: 44px;
      .vti__input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #7191BF;
        display: none;
      }
      .vti__dropdown-arrow {
      }
    }
  }
  .sign-nda {
    .vs__dropdown-menu {
      width: 100%;
      z-index: 2;
    }
  }
  .v-select {
    &.vs--open {
      .vs__selected-options {
        .vs__selected {
          display: none;
        }
      }
    }
  }
  .vs__dropdown-menu {
    position: absolute;
    background: #fff;
    width: 100%;
    list-style: none;
    border-radius: 10px 0px 10px 10px;
    z-index: 1;
    padding: 4px;
    max-height: 300px;
    overflow-x: scroll;
    .vs__dropdown-option {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #03A9F4;
      padding: 10px;
      border-radius: 6px;
      &:hover, &:active, &:focus {
        background: #EBF9FE;
      }
    }
  }
  .vs__dropdown-toggle {
    position: relative;
    .vs__actions {
      position: absolute;
      top: 0;
      right: 5px;
      .vs__clear {
        display: none;
      }
    }
    .vs__open-indicator {
      background: url("@/assets/images/svg/arrowdown.svg");
      width: 20px;
      height: 20px;
      margin-top: 10px;
      path {
        fill: transparent;
      }
    }
    .vs__selected-options {
      border-radius: 8px;
      .vs__selected {
        position: absolute;
        top: 10px;
        left: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      input {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 2px 15px rgba(1, 84, 122, 0.07);
        border-radius: 8px;
        border: 0px;
        height: 44px;

        .v-select.vs--single.vs--searchable.item .vs__dropdown-toggle,
        .v-select.vs--single.vs--searchable.item,
        &.vs__search {
          border-radius: 8px;
        }
        &[type="search"] {
          border-radius: 8px;
          -webkit-appearance: none;
        }
        &::placeholder {
        }
      }
    }
  }
  .create-nda {
    .vs__dropdown-menu {
      width: 100%;
      overflow-y: scroll;
      max-height: 300px;
    }
  }
  .page_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
  .f-s-11 {
    font-size: 11px;
  }
  @media screen and (max-width: 992px) {
    .joinder_sent,
    .joined,
    .forwarded,
    .joinder_received {
      font-size: 11px;
    }

    form .small-mobile-label.form_label ,
    form .small.small-mobile-label.form_label ,
    .small-mobile-label.form_label ,
    form .small-mobile-label.form-label ,
    form .small.small-mobile-label.form-label ,
    .small-mobile-label.form-label {
      font-size: 9px;
    }
    .page_title {
      font-size: 19px;
      line-height: 24px;
    }
    .sub-title-u-bold {
      font-size: 16px;
      line-height: 19px;
    }
    .cbox-form-label {
      font-size: 11px;
      line-height: 16px;
    }
    .text-inputs {
      .cbox-group-wrap {
        .form_label,
        .form-label {
          font-weight: 400;
          font-size: 9px;
          line-height: 14px;
          margin-bottom: 0;
        }
      }
    }
    .wrpapped-cbox {
      .cbox-group-wrap {
        .form_label,
        .form-label {
          font-weight: 400;
          font-size: 9px;
          line-height: 14px;
        }
        .input_group {
          padding: 0 15px;
        }
      }
    }
    .main-cbox {
      .input_group {
        align-items: center;
      }
    }
    .wrapper1 {
      .main-cbox {
        .input_group {
          align-items: start;
        }
      }
    }
    .section-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    .md-visible{
      display: none;
      &.flex {
        display: none;
      }
    }
    .sm-visible{
      display: block;
      &.flex {
        display: flex;
      }
    }
    .sub-title-semi {
      font-weight: 700;
      font-size: 13px;
      line-height: 19px;
    }
    .d_w_50 {
      width: 100%;
    }
    .input_group .form_label ,
    .input_group .form-label ,
    .form .input_group .form-label ,
    .form_label,
    .form-label {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
    }
    .btn {
      font-size: 13px;
      line-height: 19px;
    }
  }
  .transparent {
    color: transparent;
  }
}
.vs__dropdown-menu {
  overflow-x: auto!important;
  overflow-y: auto!important;
}
#profileForm {
  .vue-tel-input {
    height: 44px;
  }
  .vs__dropdown-toggle .vs__selected-options input {
    color: #013349;
    &::placeholder {
      color: #013349;
    }
  }
  .vs__selected-options {
    .vs__selected {
      .vs__search {
        &::placeholder {
          color: #013349;
        }
      }
    }
  }
}
.btn  {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  &.bright-green {
    background: #1DBA1D;
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    &:disabled {
      color: #E9EFF9;
      background: #7191BF;
    }
  }
  &.danger {
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(10, 17, 182, 0.2);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    &:disabled {
      background: #E9EFF9;
      color: #7191BF;
    }
  }
  &.no-btn {
    padding: 0px 10px;
  }
  &.upload {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #03A9F4;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(10, 17, 182, 0.2);
    border-radius: 10px;
    padding: 10px 20px;
  }
  &.white {
    background: #FFFFFF;
    color: #03A9F4;
    box-shadow: 0px 3px 10px rgba(10, 17, 182, 0.2);
    border-radius: 10px;
    &:hover {
      background: #EBF9FE!important;
      color: #03A9F4!important;
    }
  }
  &.light-blue {
    background: #03A9F4;
    box-shadow: 0px 3px 10px rgba(10, 17, 182, 0.2);
    color: #FFFFFF;
    &:hover {
      background: #0295F0!important;
      color: #FFFFFF!important;
    }
  }
  &.blue {
    background: #0295F0;
    box-shadow: 0px 3px 10px rgba(10, 17, 182, 0.2);
    border-radius: 10px;
  }
}
.flex_column {
  display: flex;
  flex-direction: column;
  &.inline {
    flex-direction: row;
  }
}
.breadcrumbs {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #03A9F4;
}
.nda_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.hands-icon {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: -15px;
  max-width: 114px;
  max-height: 128px;
}
.flex-left {
  align-items: start;
}
.title {
  &.modal-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 15px;
  }
}
.break-spaces {
  white-space: break-spaces;
}
@media screen and (max-width: 992px) {
  .modal-logo {
    max-width: 145px;
  }
  .title {
    &.modal-title {
      font-size: 13px;
      line-height: 19px;
    }
  }
  img.hands-icon {
    top: 56px;
  }
  .hands-icon {
    top: 54px;
    width: 128px;
    max-width: 128px;
    height: auto;
    max-height: 150px;
  }
  .nda_title {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
  }
  .breadcrumbs {
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
  }
  .form_label_sm {
    font-weight: 500;
    font-size: 9px;
    line-height: 14px;
    color: #7191BF;
  }
  .page {
    .page-container {
      padding: 22px;
      vs__dropdown-toggle .vs__selected-options,
      vs__dropdown-toggle .vs__selected-options input,
      .vs__selected-options {
        .vs__selected {
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
        }
      }
      .vue-tel-input {
        .vti__input {
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          display: none;
        }
        .vti__dropdown-arrow {
        }
      }
    }
  }
}
.modal {
  &.reminder_success {
    .title_line {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .massage {
      text-align: center;
    }
  }
}
.prev-nda {
  .preview .inner .body .nda .item {
    .item-head {
      background: #F5FfFF;
    }
  }
}
.filename {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.filesize {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7191BF;
}
.text-disabled {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A5BCDD;
}
.bg-white {
  background: #fff;
}
.top-br-10 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.bottom-br-10 {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.br-10 {
  border-radius: 10px;
}
.d_w_50 {
  width: 50%;
}
.w_50 {
  width: 50%;
}
.w_60 {
  width: 60%;
}
.w_40 {
  width: 40%;
}
.w_55 {
  width: 55%;
}
.w_35 {
  width: 35%;
}
.w_65 {
  width: 65%;
}
.w_10 {
  width: 10%;
}
.w_21 {
  width: 21%;
}
.w_55 {
  width: 55%;
}
.w_45 {
  width: 45%;
}
.w_40 {
  width: 40%;
}
.w_70 {
  width: 70%;
}
.w_75 {
  width: 75%;
}
.w_1_6 {
  width: calc(100%/6);
}
.w_33 {
  width: calc(100%/3);
}
.w_30 {
  width: 30%;
}
.w_100 {
  width: 100%;
}
.w_20 {
  width: 20%;
}
.w_25 {
  width: 25%;
}
.w_calc_big_part {
  width: calc(75%/3);
}
.w_calc_small_part {
  width: calc(25%/3);
}

.inline {
  .elements {
    &>div {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
}
.gap-18p {
  gap: 18px;
}
.gap-11p {
  gap: 11px;
}
.gap-10p {
  gap: 10px;
}
.gap-8p {
  gap: 8px;
}
.gap-4p {
  gap: 4px;
}
.gap-7p {
  gap: 7px;
}
.gap-6p {
  gap: 6px;
}
.gap-20p {
  gap: 20px;
}
.gap-23p {
  gap: 23px;
}
.gap-25p {
  gap: 25px;
}
.gap-30p {
  gap: 30px;
}
.gap-40p {
  gap: 40px;
}
.gap-45p {
  gap: 45px;
}
.gap-16p {
  gap: 16px;
}
.gap-15p {
  gap: 15px;
}
.gap-40p {
  gap: 40px;
}
.gap-80p {
  gap: 80px;
}
.p-15 {
  padding: 15px;
}
.p-20p {
  padding: 20px;
}
.mt-10p {
  margin-top: 10px;
}
.mt-3p {
  margin-top: 3px;
}
.pt-10p {
  padding-top: 10px;
}
.pt-30p {
  padding-top: 30px;
}
.pt-20p {
  padding-top: 20px;
}
.pb-20p {
  padding-bottom: 20px;
}
.pb-10p {
  padding-bottom: 10px;
}
.pb-15p {
  padding-bottom: 15px;
}
.pb-30p {
  padding-bottom: 30px;
}
.p-0 {
  padding: 0;
}
.m-0 {
  margin: 0;
}
.btn {
  border-radius: 10px;
  padding: 10px 20px;
}
.justify_content_center {
  justify-content: center;
}
.sign-wrap {
  &:hover, &:active, &:focus {
    .place_holder {
      display: none;
    }
  }
  .place_holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    text-align: center;
    margin: auto;
  }
}
.l_input_disabled {
  padding: 10px 8px;
  gap: 6px;
  min-height: 44px;
  background: #E9EFF9;
  border-radius: 8px;
}
.page-container {
  padding: 40px;
}
.sign-block {
  margin-bottom: 30px;
  .sign-wrap {
    .sign-area {
      canvas {
        max-width: 100%;
      }
    }
  }
  .modified {
    margin-bottom: 30px;
    .info {
      padding: 20px;
      gap: 6px;
      width: fit-content;
      border: 1px solid #7191BF;
      border-radius: 10px;
      margin: auto;
      .sub-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: auto;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .title {
    text-align: left;
  }
  .sub-title {
    margin: 22px auto 30px;
  }
  .sign-area {
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 30px rgba(1, 84, 122, 0.04);
    border-radius: 10px;
  }
}
.nowrap {
  white-space: nowrap;
}
.rotate-180 {
  transform: rotate(180deg);
}
.blue-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #03A9F4;
}
.joined,
.joinder_sent {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #CA27D9;
  padding: 2px 13px;
  border-radius: 12px;
  background: #FBD4FF;
}
.forwarded  {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #5200FF;
  padding: 2px 13px;
  border-radius: 12px;
  background: #E9DEFF;
}
.joinder_received {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #FF7A00;
  padding: 2px 13px;
  background: #FFEBD9;
  border-radius: 12px;
}

.new {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #FF0800;
  padding: 2px 13px;
  background: #FFE0DF;
  border-radius: 12px;
  text-align: center;
  width: fit-content;
}
.align_items_center {
  align-items: center;
}
.justify_content_between {
  justify-content: space-between;
}
.justify_content_end {
  justify-content: end;
}
.toastify {
  background: rgba(255, 0, 60, 0.73);
  transform: translate(0px, 0px);
  top: 15px;
  width: 300px;
  margin-right: 0;
  margin-left: auto;
  padding: 10px;
  color: #fff;
  .toast-close {
    color: #ffffff00;
    position: relative;
    background: none;
  }
}

.to-capitalize{
  &:first-letter {
    text-transform: uppercase;
  }
}
.input-style-disabled {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7191BF;
  padding: 10px 14px;
  background: #E9EFF9;
  border-radius: 8px;
}
.disabled_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A5BCDD;
}
.dropdown {
  .dropdown-menu {
    background: #FFFFFF;
    padding: 4px;
    box-shadow: 0px 4px 15px rgba(1, 84, 122, 0.1);
    border-radius: 10px 0px 10px 10px;
    li {
      padding: 0;
      a {
        padding: 15px 10px;
        width: 100%;
        &:hover, &:active, &:focus {
          background: #EBF9FE;
          border-radius: 6px;
        }
      }

    }
  }
}
.dropdown-menu.show {
  width: max-content;
}
.nda-info {
  .dropdown {
    .dropdown-menu.show {
      min-width: 170px;
      &.rows-select {
        min-width: max-content;
      }
    }
  }
}
.section-number {
  min-width: 32px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  background: #7191BF;
  border-radius: 16px;
  padding: 15px;
}
.l_table {
  filter: drop-shadow(0px 2px 15px rgba(1, 84, 122, 0.07));
  border-radius: 10px;
  position: relative;
  z-index: 1;
  .l_rows {
  }
  .l_row {
    padding: 20px;
    cursor: pointer;
    background: #fff;
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:hover, &:active, &:focus {
      background: #EBF9FE;
    }
  }
}
.italic {
  font-style: italic;
  &-grey {
    color: #7191BF;
    font-style: italic;
  }
}
.nda-data {
  .dropdown {
    width: 100%;
    .dropdown-menu {
      margin: 0px;
    }
    li {
      padding: 15px 10px;
      width: 100%;
      &:hover, &:active, &:focus {
        background: #EBF9FE;
        border-radius: 6px;
      }
    }
    .dropdown-toggle {
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        right: 8px;
        position: absolute;
        background: url("@/assets/images/svg/arrowdown.svg");
      }

      &:after {
        display: none;
      }
    }
  }
}
.filters {
  background: #FAFAFD;
  padding: 15px 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  .filter {
    .name {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #7191BF;
    }
  }
  .icon {
    &>div{
      max-height: 18px;
    }
  }
}
</style>
