<template>
  <div class="modal"
       :ref='modalId'
       :id="modalId" tabindex="-1"
       v-click-outside="onClickOutside"

  >
    <div class="modal-dialog  modal-lg"
    >
      <div class="modal-content">
        <div class="modal-body flex align-items-center justify-content-between p-15">
          <div class="title_modal sub-title-semi w_100">
            <slot name="slot-modal-title"></slot>
            <div v-show='modalError' class='modal-error'>{{modalError}}</div>
          </div>
          <slot name='slot-close-modal'></slot>
        </div>
        <div class="modal_body ">
          <slot name='slot-modal-form'></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from "bootstrap";
import {mapGetters, mapMutations} from "vuex";
import {modalIds} from "@/services/enums";
export default {
  name: "ModalsTemplate",
  components: {
  },
  props: {
    modalId: {
      type: String,
      default: ''
    },
    stay_on_page: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      modalStarted: null,
      modalError: null,
      allowToHide: false,
      modal_elenemt: null
    }
  },
  methods: {
    ...mapMutations([
        'setCloseModal'
    ]),
    closeThisModal(id) {
      this.setCloseModal(id)
    },
    async createModal() {
      const newModal = document.getElementById(this.modalId);
      this.modalStarted = await new Modal(newModal);
      this.modal_elenemt = document.getElementById(this.modalId)
      this.modal_elenemt.addEventListener('hidden.bs.modal', () => {
        if (this.modalId === modalIds.NDASuccess) {
          return this.setCloseModal(modalIds.NDASuccess);
        }
      })
    },
    async onClickOutside(){
      if (!this.modalStarted._isShown && this.modalId === 'ProposeTerms' && this.allowToHide) {
        await this.setCloseModal(modalIds.ProposeTerms)
      } else {
        this.allowToHide = true;
      }
    }
  },
  async mounted() {
    await this.createModal();
  },
  watch: {
    async getOpenModal(val) {
      if(val === this.modalId && this.modalStarted) {
        await this.modalStarted.show();
      }
    },
    async getCloseModal(val) {
      if(val === this.modalId) {
        await this.modalStarted.hide();
        if (this.stay_on_page) {
          console.log(this.stay_on_page)
          return;
        }
        if (val === modalIds.NDASuccess && (!this.stay_on_page && !this.getStayThisPage)) {
          await this.$router.push({name: 'UserDashboard'});
        }
      }
    },
    getModalError(val) {
      this.modalError = val;
    },
  },
  unmounted() {
    if (this.modalStarted) {
      this.modalStarted.dispose();
    }
  },
  computed: {
    ...mapGetters([
      'getOpenModal',
      'getCloseModal',
      'getModalError',
        'getStayThisPage'
    ])
  }
}
</script>

<style lang='scss' scoped>
#ProposeTerms,
#registerForm{
  .modal-dialog {
    max-width: 670px;
  }
}
#ChangePassword,
#loginForm{
  .modal-dialog {
    max-width: 440px;
    top: 15%;
  }
}
#ProposeExceptionTerms,
#ProposeTermsException{
  .modal-dialog {
    width: 90%;
    max-width: 1350px;
    top: 15%;
  }
}
#SuccessRestoredNDAModal,
#NDASuccessNewVersion,
#logOutPopUp,
#NDASuccess {
  .modal-dialog {
    max-width: 440px;
    top: 25%;
  }
}
.modal {
  width: 100%;
  background: rgba(1, 51, 73, 0.85);
  .modal-error {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    color: #FF0800;
    padding-top: 15px;
    text-align: center;
  }
  .terms {
    padding-top: 24px;
    padding-bottom: 24px;
    input {
      width: 20px;
      height: 20px;
    }
  }
  .eye {
    top: 9px;
    right: 10px;
  }
  .modal-dialog {
    top: 2%;
    border-radius: 10px;
    margin: auto;
    &.login-dialog {
      max-width: 440px;
    }
  }
  .modal_body {
    background: #FAFAFD;
    padding: 15px 30px;
  }
  input {
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(1, 84, 122, 0.07);
    border-radius: 8px;
    border: 0px;
    height: 44px;
  }
}
@media screen and (max-width: 992px) {

  .modal {
    .modal_body {
      padding: 15px;
    }
    .modal-dialog {
      padding: 0 22px;
    }
  }
  .title_modal{
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
  }
  .login-form {
    .sub-title {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
    }
  }
}
</style>
