import * as moment from 'moment-timezone';

export const STR_FORMAT = 'DD MMM YYYY';
export const STR_FORMAT_SLASHES = 'MM/DD/YY';
export const DATE_STR_FORMAT_DOTS = 'MM.DD.YY';
export const TIME_STR_FORMAT_DOTS = 'HH:mm';
export const ISO_DATE = 'YYYY-MM-DD';
export const ISO_DATE_TIME = 'YYYY-MM-DDTHH:mm:ssZ';
export const TIMESTAMP_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const TIME_12_HOURS = 'hh:mm A';
export const ISO_TZ = 'Z';
export const WEEK_DAY = 'dddd';
export const MONTH_NAME = 'MMMM';
export const MINUTES_PER_SLOT = 15;
export const UTC_OFFSET = '+00:00';
export const MONTH_DEY_YEAR = 'MMM DD, YYYY';
export const WEEKS_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',];
export const TZ = 'America/New_York';

export const dateToStr = (date) => {
  return moment(date).format(STR_FORMAT_SLASHES);
};

export const dateTimeTZToStr = (data) => {
  const date = moment(data).format(DATE_STR_FORMAT_DOTS);
  const time = moment(data).format(TIME_STR_FORMAT_DOTS);
  // const tz = moment(data).zoneAbbr();

  return `${date} at ${time}`
};

export const currentTimeISO = () => {
  return moment().utcOffset(0).format(STR_FORMAT_SLASHES);
};

export const timeToISO = (time) => {
  return moment(time).format(TIMESTAMP_DATE_TIME);
};

export const timeToISOInEST = (time) => {
  return moment(time).tz(TZ).format(ISO_DATE_TIME);
};

export const timeToStr = (time) => {
  return moment(time).format(STR_FORMAT);
}

export const startEndPeriod = (startTime, endTime, full = false) => {
  const startYear = moment(startTime).format('YYYY');
  const endYear = moment(endTime).format('YYYY');
  const mounthFormat = full ? 'MMMM' : 'MMM';

  if (startYear === endYear) {
    return `${moment(startTime).tz(TZ).format('DD')}th ${moment(startTime).tz(TZ).format(mounthFormat)} - ${moment(endTime).tz(TZ).format('DD')}th ${moment(endTime).tz(TZ).format(mounthFormat)} ${endYear}`;
  }
  return `${moment(startTime).tz(TZ).format('DD')}th ${moment(startTime).tz(TZ).format(mounthFormat)} ${startYear} - ${moment(endTime).tz(TZ).format('DD')}th ${moment(endTime).tz(TZ).format(mounthFormat)} ${endYear}`;
}

export const dateMDYtoEST = (date) => {
  return moment(date).tz(TZ).format(MONTH_DEY_YEAR);
}

export const dateToEST = (date) => {
  return moment(moment(date).utcOffset(0)).tz(TZ).toDate();
}

export const dateFromInWeeks = (startDate, endDate) => {
  const end = moment(endDate);
  const start = moment(startDate);
  const in_weeks = moment.duration(end.diff(start)).asWeeks();
  return Math.ceil(in_weeks);
}

export const getTimeHM = (time) => {
  return moment(time).tz(TZ).format(TIME_12_HOURS);
}
