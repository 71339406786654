<template>
  <div class='login'>
    <ModalsTemplate
        key="loginForm"
        :modal-id='"loginForm"'
    >
      <template v-slot:slot-modal-title>
        <SmallModalLogo class='modal-logo'/>
      </template>
      <template v-slot:slot-close-modal>
        <div class='close cp' @click='closeModal'>
          <CloseIcon/>
        </div>
      </template>
      <template v-slot:slot-modal-form>
        <div class='title modal-title'>
          {{ forgotPass ? 'Forgot password' : 'Sign In' }}</div>
        <form @submit.prevent='loginUser' class='login-form form flex flex-column gap-20p'>
          <div v-show='forgotPass && !forgot_password_sent' class='sub-title'>
            To proceed please enter your account email
          </div>
          <div v-show='forgotPass && forgot_password_sent' class='sub-title'>
            If an account for <span class='bold'>{{this.loginData.email}}</span> exists, an e-mail will be sent with further instructions.
          </div>
          <div class="" v-show='!forgot_password_sent'>
            <label class="form-label">Email</label>
            <input v-model='loginData.email' type="text" class="form-control" placeholder="">
            <div class="form-error" v-if='loginDataValidator.email'>
              {{ loginDataValidator.email }}
            </div>
          </div>
          <div v-show='!forgotPass' class="">
            <label class="form-label w_100 flex justify-content-between align-items-center">
              <span>Password</span>
              <span
                  @click='openForgotForm'
                  class='forgot link cp'>
                Forgot password?
              </span>
            </label>
            <div class='position-relative'>
              <input
                  :type='passwordType'
                  ref='password' v-model="loginData.password" class="form-control">
              <div class='eye cp position-absolute'>
                <EyeOn @click='passwordShow = !passwordShow'/>
              </div>
              <div class="form-error" v-if='loginDataValidator.password'>
                {{ loginDataValidator.password }}
              </div>
            </div>
          </div>
          <div class='actions flex gap-15p justify-content-between align-items-center'>
            <div v-show='!forgotPass' class='to-sign-up flex flex-column w_50 text-center'>
              <div class=''>Don’t have an account?</div>
              <a href='#' @click='openSignUp' class="link bold btn no-btn" >Sign Up</a>
            </div>
            <button
                v-show='!forgot_password_sent'
                :class='{"w_50": !forgotPass, "w_100": forgotPass}'
                class="btn bold light-blue" @click='loginUser'>
              {{ forgotPass ? 'Password reset' : 'Sign In' }}
            </button>
            <a href='#'
                v-show='forgot_password_sent'
                class="btn bold light-blue w_100" @click='goLogin'>
              Ok
            </a>
          </div>
        </form>
      </template>
    </ModalsTemplate>
  </div>
</template>

<script>
import EyeOn from "@/components/SVG/EyeOn";
import ModalsTemplate from "@/components/Layouts/ModalsTemplate";
import {mapMutations} from "vuex";
import {forgotPassword, logIn} from "@/services/api";
import {isNotValidEmail, removeModalArtifacts, saveObjectToStorage} from "@/services/helpers";
import {FormFieldValidators, modalIds} from "@/services/enums";
import CloseIcon from "@/components/SVG/CloseIcon";
import SmallModalLogo from "@/components/SVG/SmallModalLogo";

export default {
  name: "LogIn",
  components: {
    EyeOn,
    CloseIcon,
    ModalsTemplate,
    SmallModalLogo
  },
  data() {
    return {
      forgotPass: false,
      passwordType: 'password',
      forgot_password_sent: false,
      passwordShow: false,
      loginData: {
        password: null,
        email: null,
      },
      loginDataValidator: {
        password: null,
        email: null,
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCloseModal',
      'setOpenModal',
      'showLoader',
      'hideLoader',
      'setModalError',
      'setLoggedUser',
    ]),
    clearValid() {
      this.setModalError(null)
      const validator = this.loginDataValidator;
      Object.keys(validator).forEach(field => {
        validator[field] = null;
      })
    },
    async closeModal() {
      this.forgotPass = false;
      await this.setCloseModal(modalIds.loginForm)
    },
    clearForm() {
      const fields = this.loginData;
      Object.keys(fields).forEach(field => {
        fields[field] = null;
      })
    },
    async goLogin() {
      await this.clearForm()
      this.forgot_password_sent = false;
      this.forgotPass = false;
    },
    async loginUser() {
      await this.clearValid();
      const isValidData = await this.isValidFormLogin();
      if (!isValidData) {
        return;
      }
      this.showLoader();
      if (this.forgotPass) {
        delete this.loginData.password;
        const success = await forgotPassword(this.loginData);
        if (success) {
          this.forgot_password_sent = true;
        }
        this.hideLoader();
        return;
      }
      try {
        const { user, access_token } = await logIn(this.loginData);
        if (!access_token || !user) {
          this.hideLoader();
          return await this.setModalError("Wrong password or Email doesn’t exist. Please check this fields.")
        }

        await this.hideLoader();
        await this.closeModal()
        await localStorage.setItem('user_jwt', access_token);
        await saveObjectToStorage('stored_user', user);
        await this.setLoggedUser(user);
        if (this.$route?.name === "SignedNdaNotice") {
          const nda_id = this.$route.params.nda_id;
          const user_id = this.$route.params.user_id;
          return await this.$router.push({name: 'PreviewNDA', query: {
              nda_id: nda_id,
              user_id: user_id
            }})
        }
        if (this.$route?.params?.id && this.$route?.name === "RequestedChangesNdaRedirectId") {
          return await this.$router.push({name: "ViewNDA", params: {id: this.$route?.params?.id}})
        }
        if (this.$route?.name === "SignChangedNDA") {return;}
        if (this.$route?.name === "RespondChangesNdaRedirect") {
          return await this.$router.push({name: 'SignChangedNDA', query: {
              nda_id: this.$route?.params?.id,
              user_id: this.$route?.params?.user_id
            }})
        }
        await removeModalArtifacts();
        if (localStorage.joined_nda_id) {
          return this.$router.push({name: 'SignJoinedNDA', params: {id: localStorage.joined_nda_id}});
        }
        if (!['SignedNdaNotice', 'SignChangedNDA', 'RespondChangesNdaRedirect', 'PreviewNDA'].includes(this.$route?.name)) {
          await this.$router.push({name: "UserDashboard"})
        }

        const exists_user_new_nda = await JSON.parse(localStorage.getItem('exists_user_new_nda')) || null;
        if (exists_user_new_nda) {
           return await this.$router.push({name: "SignNDA", params: {id: exists_user_new_nda.nda_id}})
        }
      } catch (e) {
        await this.setModalError("Wrong password or Email doesn’t exist. Please check this field.")
      }
      this.hideLoader();
    },
    async openForgotForm() {
      this.forgotPass = true;
      await this.setOpenModal(modalIds.loginForm)
    },
    async isValidFormLogin() {
      let is_valid = true;
      if (this.loginData.email) {
        if (isNotValidEmail(this.loginData.email)) {
          this.loginDataValidator.email = "Wrong email format. Please, try again";
          is_valid = false;
        }
      }
      if (!this.loginData.email) {
        this.loginDataValidator.email = "This field is required.";
        is_valid = false;
      }
      if (!this.loginData.password && !this.forgotPass) {
        this.loginDataValidator.password = "This field is required.";
        is_valid = false;
      }
      if (this.loginData.password && !this.forgotPass && this.loginData.password.length < FormFieldValidators.passwordLength) {
        this.loginDataValidator.password = "Minimum password length is 8 characters.";
        is_valid = false;
      }
      return is_valid;
    },
    async openSignUp() {
      await this.setCloseModal(modalIds.loginForm)
      await this.setOpenModal(modalIds.registerForm)
    }
  },
  watch: {
    passwordShow(val) {
      if (!val) {
        return this.passwordType = 'password'
      }
      return this.passwordType = 'text'
    }
  }
}
</script>

<style lang='scss' scoped>
#app {
  .login-form {
    .sub-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #013349;
    }
    .forgot {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
    .eye {
      top: 9px;
      right: 10px;
    }
    .to-sign-up {
      font-size: 12px;
      line-height: 18px;
      .btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
    @media screen and (max-width: 992px) {
      .forgot {
        font-size: 11px;
        line-height: 16px;
      }
      .to-sign-up {
        font-size: 9px;
        line-height: 14px;
      }
    }
  }
}
</style>
