<template>
  <div class='land-block'>
    <div class='inner flex flex-column justify-content-around align-items-center gap-30p'>
      <div class='title'>Fall in love with NDAs, we promise.</div>
      <div class='btn white bold' @click='openRegisterForm'>Start for free now</div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {modalIds} from "@/services/enums";

export default {
  name: "FallInLove",
  methods: {
    ...mapMutations([
      'setOpenModal'
    ]),
    async openRegisterForm() {
      await this.setOpenModal(modalIds.registerForm)
    },
  }
}
</script>

<style lang='scss' scoped>
.land-block {
  .inner {
    padding: 60px;
    background: #03A9F4;
    border-radius: 20px;
    .title {
      font-weight: 700;
      font-size: 26px;
      line-height: 34px;
      color: #FFFFFF;
    }
    .btn {
      padding-right: 100px;
      padding-left: 100px;
    }
  }
  @media screen and (max-width: 992px) {
    .inner {
      padding: 30px;
      gap: 15px;
      .title {
        font-weight: 700;
        font-size: 21px;
        line-height: 27px;
        text-align: center;
      }
      .btn {
        font-weight: 700;
        font-size: 13px;
        line-height: 19px;
        padding: 10px 20px;
        border-radius: 10px;
      }
    }
  }
}
</style>
