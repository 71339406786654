<template>
  <div class='wrapper'>
    <div class='header align-items-center flex justify-content-between'>
      <div class='logo cp'>
        <router-link :to='{name: is_logged_user ? "UserDashboard" : "HomePage"}'><MainLogo/></router-link>
      </div>
      <div v-if='is_logged_user' class='md-visible top-menu logged actions align-items-center flex justify-content-end gap-20p'>
        <router-link v-for='(item) in top_menu'
                     :key='item.link.path'
                     :to='{path: item.link.path}' class='menu_item'>{{ item.link.name }}</router-link>
        <div class='dropdown'>
          <CustomDropdown
              :dropdownData='dropdownOptions'
              :default_name='userName'
          />
        </div>
      </div>
      <div v-else class='md-visible actions align-items-center flex justify-content-end gap-20p'>
        <div class='btn bold white' @click='openLoginForm'>Sign In</div>
        <div class='btn bold light-blue' @click='openRegisterForm'>Sign Up</div>
      </div>
      <div class='sm-visible' @click='isOpen = !isOpen'>
        <ToggleMenuIcon v-show='!isOpen' />
        <CloseMenuIcon v-show='isOpen' />
      </div>
    </div>
    <transition name="fade">
      <div class='overlay' v-show='isOpen'></div>
    </transition>
    <transition name="fade">
      <div class='sm-visible mobile_menu' v-show='isOpen'>
        <div v-if='is_logged_user' class='user_links flex align-items-center justify-content-between'>
          <div class='name'>
            {{ userName }}
          </div>
          <div class='log-out cp' @click='logOut'>
            <LogOut/>
          </div>
        </div>
        <div v-if='!is_logged_user' class='user_links gap-30p flex align-items-center justify-content-between'>
          <div class='btn bold w_50 white' @click='openLoginForm'>Sign In</div>
          <div class='btn bold w_50 light-blue' @click='openRegisterForm'>Sign Up</div>
        </div>
        <div v-if='is_logged_user' class='separator'></div>
        <div v-if='is_logged_user' class='top-menu logged actions align-items-center flex flex-column justify-content-end gap-20p'>
          <router-link v-for='(item) in top_menu'
                       @click='isOpen = false'
                       :key='item.link.path'
                       :to='{path: item.link.path}' class='menu_item'>{{ item.link.name }}</router-link>
          <router-link
              @click='isOpen = false'
              class='menu_item' :to='{name: "UserProfile"}'>Account</router-link>
        </div>

      </div>
    </transition>
  </div>
  <SignUpModal v-if='!userName'/>
  <LogIn v-if='!userName'/>
  <LogOutPopUp/>
</template>

<script>
import MainLogo from "@/components/SVG/MainLogo";
import SignUpModal from "@/components/SignUpModal";
import {mapGetters, mapMutations} from "vuex";
import LogIn from "@/components/LogIn";
import topMenu from "@/store/top_menu.json";
import CustomDropdown from "@/components/DropdownMenu";
import ToggleMenuIcon from "@/components/SVG/ToggleMenuIcon";
import LogOut from "@/components/SVG/LogOut";
import CloseMenuIcon from "@/components/SVG/CloseMenuIcon";
import {modalIds} from "@/services/enums";
import LogOutPopUp from "@/components/LogOutPopUp";

export default {
  name: "PageHeader",
  methods: {
    ...mapMutations([
      'showLoader',
      'hideLoader',
      'setOpenModal',
      'setLoggedUser'
    ]),
    async logOut() {
      await this.setOpenModal(modalIds.logOutPopUp);
    },
    async openRegisterForm() {
      await this.setOpenModal('registerForm')
    },
    async openLoginForm() {
      await this.setOpenModal('loginForm')
    }
  },
  data() {
    return {
      top_menu: topMenu,
      isOpen: false,
    }
  },

  components: {
    MainLogo,
    SignUpModal,
    LogIn,
    CustomDropdown,
    ToggleMenuIcon,
    LogOut,
    CloseMenuIcon,
    LogOutPopUp
  },

  computed: {
    ...mapGetters([
        'getLoggedUser'
    ]),
    userName() {
      if (this.getLoggedUser?.first_name || this.getLoggedUser?.last_name) {
        return `${this.getLoggedUser?.first_name} ${this.getLoggedUser?.last_name[0]}`
      }
      return null
    },
    dropdownOptions() {
      return {
        dropdownValue: [
          {
            id: 1,
            title: 'Account',
            link: '/profile',
            icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1175_25178)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 14.2663 2.75391 16.3566 4.02461 18.0336C6.14394 16.1474 8.93854 15 11.9999 15C15.0613 15 17.856 16.1474 19.9753 18.0337C21.2461 16.3566 22 14.2664 22 12C22 6.47715 17.5228 2 12 2ZM5.38537 19.4999C7.14821 21.0559 9.46385 22 12 22C12.1726 22 12.3442 21.9956 12.5146 21.987C14.7303 21.8747 16.7558 21.0411 18.3609 19.7165C18.4304 19.6592 18.499 19.6009 18.5669 19.5418L18.6145 19.5C16.851 17.9432 14.5362 17 11.9999 17C9.46367 17 7.1489 17.9432 5.38537 19.4999ZM0 12C0 14.8256 0.976595 17.4231 2.61066 19.4734L2.58584 19.5L3.22175 20.182C5.41097 22.5298 8.53452 24 11.9999 24H12C15.4793 24 18.6127 22.5193 20.8045 20.1536L21.414 19.5L21.3893 19.4735C23.0234 17.4232 24 14.8256 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12ZM9.5 8.5C9.5 7.11929 10.6193 6 12 6C13.3807 6 14.5 7.11929 14.5 8.5C14.5 9.88071 13.3807 11 12 11C10.6193 11 9.5 9.88071 9.5 8.5ZM12 4C9.51472 4 7.5 6.01472 7.5 8.5C7.5 10.9853 9.51472 13 12 13C14.4853 13 16.5 10.9853 16.5 8.5C16.5 6.01472 14.4853 4 12 4Z" fill="#0295F0"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1175_25178">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>`

          },
          {
            id: 2,
            title: 'LogOut',
            icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H9C9.55228 20 10 20.4477 10 21C10 21.5523 9.55228 22 9 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H9C9.55228 2 10 2.44772 10 3C10 3.55228 9.55228 4 9 4H5ZM15.2929 6.29289C15.6834 5.90237 16.3166 5.90237 16.7071 6.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L16.7071 17.7071C16.3166 18.0976 15.6834 18.0976 15.2929 17.7071C14.9024 17.3166 14.9024 16.6834 15.2929 16.2929L18.5858 13H9C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11H18.5858L15.2929 7.70711C14.9024 7.31658 14.9024 6.68342 15.2929 6.29289Z" fill="#03A9F4"/>
                    </svg>`
          },
        ],
        dropdownSelectedItem: {
          id: 1,
          title: this.userName || 'Name',
        },
        selectField: 'title',
      };
    },
    is_logged_user() {
      const user = this.getLoggedUser;
      if (user?.id) {
        return true
      }
      return false
    },
  }
}
</script>

<style lang='scss' scoped>
.fade-enter-from{
  opacity: 0;
  transform: translateX(-3rem);
}
.fade-enter-active{
  transition: all 1s ease-in;
}
.fade-enter-to{
  opacity: 1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.wrapper {
  background: #FAFAFD;
  box-shadow: 0px 4px 15px rgba(1, 84, 122, 0.1);
  position:fixed;
  margin: auto;
  right: 0;
  left: 0;
  width:100%;
  z-index:200;
  height:82px;
  .top-menu {
    a.menu_item {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 15px;
      gap: 10px;
      border-radius: 10px;
      color: #03A9F4;
      text-decoration: none;
      transition: all 0.5s;
      &.router-link-active {
        transition: all 0.5s;
        background: #E9EFF9;
        color: #7191BF;
      }
    }
  }
  .logo {
    padding-left: 40px;
  }
  .actions {
    padding-right: 30px;
    padding-top: 18px;
    padding-bottom: 18px;
    .btn {
      font-weight: 700;
    }
  }
}
@media screen and (max-width: 992px) {
  .overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    top: 100px;
    z-index: 9;
    background: rgba(1, 51, 73, 0.85)
  }
  .mobile_menu {
    position: absolute;
    top: 62px ;
    width: 100%;
    z-index: 10;
    left: 0;
    background: #FAFAFD;
    box-shadow: 0px 4px 15px rgba(1, 84, 122, 0.1);
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 20px;
    .user_links {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .separator {
    width: 100%;
    height: 1px;
    background: #E9EEF9;
    margin-bottom: 15px;
  }
  .wrapper {
    padding: 15px 22px;
    height:62px;
    .logo {
      padding: 0;
    }
    .actions {
      padding: 0;
    }
    .logged {
      .dropdown {
        display: none;
      }
    }
  }
}
</style>
