<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8242 15.6813C12.4268 16.2592 11.5734 16.2592 11.1761 15.6813L7.57709 10.4464C7.12093 9.78291 7.59595 8.87988 8.40113 8.87988L15.5991 8.87988C16.4043 8.87988 16.8793 9.78291 16.4231 10.4464L12.8242 15.6813Z" fill="#03A9F4"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowDownBlue"
}
</script>

<style scoped>

</style>
